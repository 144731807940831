import React, {ChangeEvent, FC, useEffect, useState} from 'react';
import useSpinnerLoader from '../../shared/components/spinner-loader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretLeft, faPenToSquare} from '@fortawesome/free-solid-svg-icons';
import {Button} from 'react-bootstrap';
import {AxiosError} from 'axios';
import {notificationService} from '../../shared/services/notification-service';
import {PermissionService} from '../../shared/services/permission-service';
import {ModuleType, PermissionModel, PermissionTable} from '../../shared/models/permission.model';
import {useAuth} from '../../shared/authentications/auth-context';
import InfoModal from '../../shared/components/molecules/modals/info-modal';
import {initialPermission} from '../../shared/utils/constants';
import {apiEmployeeService} from "../../shared/services/employee-service";

const PermissionMePage: FC = () => {
  const {loaderComponent, isLoading, startLoading, stopLoading} = useSpinnerLoader();
  const {authJwt} = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState<PermissionModel>(initialPermission);
  const [requestData, setRequestData] = useState<PermissionModel>(initialPermission);
  const [isApprovable, setIsApprovable] = useState(false);

  const [edited, setEdited] = useState(false);
  const permissionModule: PermissionTable[] = [
    {
      name: 'ทะเบียนประวัติบุคลากร',
      module: 'employees',
    },
    {
      name: 'บริหารโครงสร้างองค์กร',
      module: 'organizational_structures',
    },
    {
      name: 'จัดการผู้ใช้งานทั้งหมด',
      module: 'employee_actives',
    },
    {
      name: 'คำขอผู้ใช้งานใหม่',
      module: 'employee_register_requests',
    },
    {
      name: 'คำขอรีเซ็ตรหัสผ่าน',
      module: 'employee_reset_password_requests',
    },
    {
      name: 'คำขอผู้ใช้งานแก้ไขข้อมูล',
      module: 'employee_edit_requests',
    },
    {
      name: 'อนุมัติคำขอสิทธิ์การเข้าถึง',
      module: 'permission_approve',
    },
    // {
    //   name: 'ลาออนไลน์ / ประวัติการลา',
    //   module: 'employee_leaves',
    // },
    {
      name: 'อนุมัติการลา',
      module: 'employee_leave_approve',
    },
    {
      name: 'ตั้งค่าการลา',
      module: 'employee_leave_setting',
    },
    {
      name: 'ตารางกะ',
      module: 'employee_shift_personel_view',
    },
    {
      name: 'กำหนดตารางกะ',
      module: 'employee_shift',
    },
    {
      name: 'ตารางกะรายบุคคล',
      module: 'employee_shift_personel',
    },
    {
      name: 'เข้า-ออกงานสำรอง',
      module: 'employee_reserve_checkin',
    },
    {
      name: 'จัดการเข้า-ออกงานสำรอง',
      module: 'employee_reserve_checkin_manage',
    },
    // {
    //   name: 'รายงานการเข้า-ออกงาน',
    //   module: 'employee_checkin_report',
    // },
    {
      name: 'ตรวจสอบสถานะเครื่อง',
      module: 'device_status',
    },
    {
      name: 'รายงาน',
      module: 'report',
    },
    {
      name: 'รายงานผู้ใช้งานระบบ',
      module: 'system_logs',
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    startLoading();
    try {
      const result = await PermissionService.getMyPermission();
      let permission = result;

      const updatePermissions = (perms:any , isHighLevelPosition?: boolean) => ({
        ...perms,
        // cancel high level pos
        // employee_leave_approve: {
        //   read: !!isHighLevelPosition,
        //   create: !!isHighLevelPosition,
        //   update: !!isHighLevelPosition,
        //   delete: !!isHighLevelPosition,
        //   export: !!isHighLevelPosition,
        //   approve: !!isHighLevelPosition,
        // }
      });

      if (authJwt?.employee_id) {
        const employee = await apiEmployeeService.getEmployeeDetail(authJwt.employee_id);
        const hasNoPosition = !employee.current_job_position;
        const isLowLevelPosition = employee.job_positions.find((position:any) => position.is_currently)?.organizational_structure_level >= 6;

        if (hasNoPosition || isLowLevelPosition) {
          setIsApprovable(false);
          permission = updatePermissions(permission, false);
        } else {
          setIsApprovable(true);
          permission = updatePermissions(permission, true);
        }
      }
      setData(permission);
      setRequestData(permission);
    } catch (error) {
      const err = error as AxiosError;
      notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
    } finally {
      stopLoading();
    }
  };

  const backToView = () => {
    setEdited(false);
    fetchData();
  };

  const onEdit = () => {
    setEdited(true);
  };

  const onSubmit = async () => {
    const data = {
      user_id: authJwt?.id || '',
      permission_request_details: Object.keys(requestData).map(key => {
        const keyname = key as ModuleType;
        return {
          module: key,
          ...requestData[keyname],
        };
      })
    };
    startLoading();
    try {
      const result = await PermissionService.createPermissionRequest(data);
      notificationService.successNotification('สร้างคำร้องขอสิทธิ์การเข้าถึงสำเร็จ', '');
      backToView();
      setShowModal(true);
    } catch (error) {
      const err = error as AxiosError;
      notificationService.dangerNotification('ไม่สามารถสร้างคำร้องขอสิทธิ์การเข้าถึง', err.message);
    } finally {
      stopLoading();
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const displayModal = () => {
    if (showModal) {
      return <InfoModal
        show={showModal}
        title={'สร้างคำร้องขอสิทธิ์การเข้าถึงสำเร็จ'}
        description={'กรุณารอ approver อนุมัติคำขอ'}
        onHide={handleCloseModal}
      />;
    }
    return null;
  };

  const buttonDisplay = () => {
    if (edited) {
      return <div className="d-flex w-50 justify-content-end">
        <Button variant="secondary" type="button" className="btn-action me-2" onClick={backToView}>
          <FontAwesomeIcon icon={faCaretLeft} className="me-2"/>
          ย้อนกลับ
        </Button>
        <button className="btn btn-primary d-block px-4 btn-action-height btn-mof-primary" type="button"
                onClick={onSubmit}>
          ส่งคำร้อง
        </button>
      </div>;
    }
    return <>
      <button className="btn btn-primary d-block px-4 btn-action-height btn-mof-primary" type="button" onClick={onEdit}>
        <FontAwesomeIcon icon={faPenToSquare} className="me-2"/>
        แก้ไขสิทธิ์การเข้าถึง
      </button>
    </>;
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>, module: string, isSelectAll?: boolean) => {
    e.persist();

    setRequestData((prevState:any) => {
      if (!isSelectAll) {
        return {
          ...prevState,
          [module]: {
            ...prevState[module],
            [e.target.name]: e.target.checked,
          },
        };
      } else {
        // const updatedPermissions = {
        //   read: e.target.checked,
        //   create: e.target.checked,
        //   update: e.target.checked,
        //   delete: e.target.checked,
        //   export: e.target.checked,
        //   approve: e.target.checked,
        // };
        return {
          ...prevState,
          // [module]: updatedPermissions,
        };
      }
    });
    setData((prevState:any) => {
      if (!isSelectAll) {
        return {
          ...prevState,
          [module]: {
            ...prevState[module],
            [e.target.name]: e.target.checked,
          },
        };
      } else {
        // const updatedPermissions = {
        //   read: e.target.checked,
        //   create: e.target.checked,
        //   update: e.target.checked,
        //   delete: e.target.checked,
        //   export: e.target.checked,
        //   approve: e.target.checked,
        // };
        return {
          ...prevState,
          //[module]: updatedPermissions,
        };
      }
    });
  };

  const displayTable = () => {
    if (isLoading) {
      return <></>;
    }
    return permissionModule.map((module, index) => {
      return <tr key={index}>
        <td className="text-center py-3">
          {module.name}
        </td>
        <td className="align-middle">
          <input className="pointer mof-form-check-input d-block m-auto" type="checkbox" name="read"
                 checked={data[module.module].read || data[module.module].read}
                 disabled={!edited /* || module.module === "employee_leave_approve" */ && !isApprovable} onChange={(e) => handleOnChange(e, module.module)} id="same-address"/>
        </td>
        <td className="align-middle">
          <input className="pointer mof-form-check-input d-block m-auto" type="checkbox" name="create"
                 checked={data[module.module].create}
                 disabled={!edited /* || module.module === "employee_leave_approve" */ && !isApprovable} onChange={(e) => handleOnChange(e, module.module)} id="same-address"/>
        </td>
        <td className="align-middle">
          <input className="pointer mof-form-check-input d-block m-auto" type="checkbox" name="update"
                 checked={data[module.module].update}
                 disabled={!edited /* || module.module === "employee_leave_approve" */ && !isApprovable} onChange={(e) => handleOnChange(e, module.module)} id="same-address"/>
        </td>
        <td className="align-middle">
          <input className="pointer mof-form-check-input d-block m-auto" type="checkbox" name="delete"
                 checked={data[module.module].delete}
                 disabled={!edited /* || module.module === "employee_leave_approve" */ && !isApprovable} onChange={(e) => handleOnChange(e, module.module)} id="same-address"/>
        </td>
        <td className="align-middle">
          <input className="pointer mof-form-check-input d-block m-auto" type="checkbox" name="export"
                 checked={data[module.module].export}
                 disabled={!edited /* || module.module === "employee_leave_approve" */ && !isApprovable} onChange={(e) => handleOnChange(e, module.module)} id="same-address"/>
        </td>
        <td className="align-middle">
          <input className="pointer mof-form-check-input d-block m-auto" type="checkbox" name="approve"
                 checked={data[module.module].approve}
                 disabled={!edited /* || module.module === "employee_leave_approve" */ && !isApprovable} onChange={(e) => handleOnChange(e, module.module)} id="same-address"/>
        </td>
        <td className="align-middle">
          <input className="pointer mof-form-check-input d-block m-auto" type="checkbox" name="approve"
                 checked={data[module.module].read && data[module.module].create && data[module.module].update && data[module.module].delete && data[module.module].export && data[module.module].approve}
                 disabled={!edited /* || module.module === "employee_leave_approve" */ && !isApprovable} onChange={(e) => handleOnChange(e, module.module, true)} id="same-address"/>
        </td>
      </tr>;
    });
  };

  return <div>
    {displayModal()}
    {loaderComponent}
    <div className="d-flex justify-content-between py-4">
      <h2 className="text-mof-primary m-0">คำร้องขอสิทธิ์การเข้าถึง</h2>
      {buttonDisplay()}
    </div>

    <table className="table">
      <thead>
      <tr>
        <th></th>
        <th className="text-center">ดูข้อมูล</th>
        <th className="text-center">สร้าง</th>
        <th className="text-center">แก้ไข</th>
        <th className="text-center">ลบ</th>
        <th className="text-center">export</th>
        <th className="text-center">อนุมัติ</th>
        <th className="text-center">ทั้งหมด</th>
      </tr>
      </thead>
      <tbody>
      {
        displayTable()
      }
      </tbody>
    </table>

  </div>;
};

export default PermissionMePage;
