import { VacationCardModel } from './components/vacation-card';
import { LeaveCardModel } from './components/leave-card';

export const vacationDataMock: VacationCardModel[] = [
  {
    month: 'มกราคม',
    vacations: [
      {
        day_name: 'วันจันทร์',
        day_number: '1',
        description: 'วันขึ้นปีใหม่',
      }
    ]
  },
  {
    month: 'กุมภาพันธ์',
    vacations: [
      {
        day_name: 'วันเสาร์',
        day_number: '24',
        description: 'วันมาฆบูชา',
      },
      {
        day_name: 'วันจันทร์',
        day_number: '26',
        description: 'วันหยุดชดเชยวันมาฆบูชา',
      },
    ],
  },
  {
    month: 'เมษายน',
    vacations: [
      {
        day_name: 'วันเสาร์',
        day_number: '6',
        description: 'วันพระบาทสมเด็จพระพุทธยอดฟ้าจุฬาโลกมหาราช เเละวันที่ระลึกมหาจักรีบรมราชวงศ์',
      },
      {
        day_name: 'วันจันทร์',
        day_number: '8',
        description: 'วันหยุดชดเชยวันพระบาทสมเด็จพระพุทธยอดฟ้าจุฬาโลกมหาราช เเละวันที่ระลึกมหาจักรีบรมราชวงศ์',
      },
      {
        day_name: 'วันศุกร์',
        day_number: '12',
        description: 'เป็นวันหยุดราชการเพิ่มเติมเป็นกรณีพิเศษอีก 1 วัน ในปี 2567',
      },
      {
        day_name: 'วันเสาร์',
        day_number: '13',
        description: 'วันสงกรานต์',
      },
      {
        day_name: 'วันอาทิตย์',
        day_number: '14',
        description: 'วันสงกรานต์',
      },
      {
        day_name: 'วันจันทร์',
        day_number: '15',
        description: 'วันสงกรานต์',
      },
      {
        day_name: 'วันอังคาร',
        day_number: '16',
        description: 'วันหยุดชดเชยสงกรานต์วันเสาร์ที่ 13 เมษายน',
      }
    ],
  },
  {
    month: 'พฤษภาคม',
    vacations: [
      {
        day_name: 'วันพุธ',
        day_number: '1',
        description: 'วันแรงงานแห่งชาติ',
      },
      {
        day_name: 'วันเสาร์',
        day_number: '4',
        description: 'วันฉัตรมงคล',
      },
      {
        day_name: 'วันจันทร์',
        day_number: '6',
        description: 'วันหยุดชดเชยวันฉัตรมงคล',
      },
      {
        day_name: 'วันศุกร์',
        day_number: '10',
        description: 'วันพระราชพิธีพืชมงคงจรดพระนังคัลเเรกนาขวัญ',
      }
    ],
  },
  {
    month: 'มิถุนายน',
    vacations: [
      {
        day_name: 'วันจันทร์',
        day_number: '3',
        description: 'วันเฉลิมพระชนมพรรษา สมเด็จพระนางเจ้าสุทิดาพัชรสุธาพิมลลักษณ พระบรมราชินี',
      },
      {
        day_name: 'วันศุกร์',
        day_number: '14',
        description: 'วันหยุดชดเชยสงกรานต์ วันอาทิตย์ที่ 14 เมษายน 2567',
      },
    ],
  },
  {
    month: 'กรกฏาคม',
    vacations: [
      {
        day_name: 'วันเสาร์',
        day_number: '20',
        description: 'วันอาสาฬหบูชา',
      },
      {
        day_name: 'วันอาทิตย์',
        day_number: '21',
        description: 'วันเข้าพรรษา',
      },
      {
        day_name: 'วันจันทร์',
        day_number: '22',
        description: 'วันหยุดชดเชยวันเข้าพรรษา',
      },
      {
        day_name: 'วันอาทิตย์',
        day_number: '28',
        description: 'วันเฉลิมพระชนมพรรษา พระบาทสมเด็จพระปรเมนทรรามาธิบดีศรีสินทรมหาวชิราลงกรณ พระวชิรเกล้าเจ้าอยู่หัว',
      },
      {
        day_name: 'วันจันทร์',
        day_number: '29',
        description: 'วันหยุดชดเชยวันเฉลิมพระชนมพรรษา พระบาทสมเด็จพระปรเมนทรรามาธิบดีศรีสินทรมหาวชิราลงกรณ พระวชิรเกล้าเจ้าอยู่หัว',
      }
    ],
  },
  {
    month: 'สิงหาคม',
    vacations: [
      {
        day_name: 'วันจันทร์',
        day_number: '5',
        description: 'วันหยุดชดเชยวันอาสาฬหบูชาวันเสารฺ์ที่ 20 กรกฎาคม 2567',
      },
      {
        day_name: 'วันจันทร์',
        day_number: '12',
        description: 'วันเฉลิมพระชนมพรรษา สมเด็จพระบรมราชชนนีพันปีหลวง เเละวันเเม่เเห่งชาติ',
      },
    ]
  },
  {
    month: 'ตุลาคม',
    vacations: [
      {
        day_name: 'วันอาทิตย์',
        day_number: '13',
        description: 'วันนวมินทรมหาราช',
      },
      {
        day_name: 'วันจันทร์',
        day_number: '14',
        description: 'วันหยุดชดเชยวันนวมินทรมหาราช',
      },
      {
        day_name: 'วันพุธ',
        day_number: '23',
        description: 'วันปิยมหาราช',
      },
    ]
  },
  {
    month: 'ธันวาคม',
    vacations: [
      {
        day_name: 'วันพฤหัสบดี',
        day_number: '5',
        description: 'วันคล้ายวันพระบรมราชสมภพของพระบาทสมเด็จวันพระบรมชนกาธิเบศรมหาภูมิพลอดุลยเดชมหาราช บรมนาถบพิตรวันชาติ เเละวันพ่อเเห่งชาติ',
      },
      {
        day_name: 'วันอังคาร',
        day_number: '10',
        description: 'วันรัฐธรรมนูญ',
      },
      {
        day_name: 'วันจันทร์',
        day_number: '30',
        description: 'วันหยุดราชการเพิ่มเติมเป็นกรณีพิเศษ[(ตามมติคณะรัฐมนตรี 24 ตุลาคม 2566)]',
      },
      {
        day_name: 'วันอังคาร',
        day_number: '31',
        description: 'วันสิ้นปี',
      }
    ]
  }
];
