import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { ApiResponse } from '../../shared/models/common.model';
import { initialResponseData } from '../../shared/utils/constants';
import useSpinnerLoader from '../../shared/components/spinner-loader';
import TableHeaderOrdering, { Header, OrderData } from '../../shared/components/table-header-ordering';
import { Button, Table, Form } from 'react-bootstrap';
import { AxiosError } from 'axios';
import { notificationService } from '../../shared/services/notification-service';
import TableRowNoData from '../../shared/components/molecules/commons/table-row-no-data';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faSearch, faEyeLowVision, faDownload, faFileExport } from '@fortawesome/free-solid-svg-icons';
import PaginationForm from '../../shared/components/molecules/commons/pagination-form';
import Moment from 'moment/moment';
import { useAuth } from '../../shared/authentications/auth-context';
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { EmployeeShiftService } from "../../shared/services/employee-shift-service";
import { ShiftRequestPayloadType, ShiftRequestType } from "../../shared/models/employee-shifts.model";
import apiService from '../../shared/services/api-service';
import {SystemLogService} from "../../shared/services/system-log-service";
import { apiEmployeeService } from '../../shared/services/employee-service';
import { Document, Page, Text, View, PDFDownloadLink, Font } from '@react-pdf/renderer';
import { ReportInOutLeaveService } from '../../shared/services/Report/ReportInOutLeave/report-in-out-leave';

const EmployeeCheckInsListPage: FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const [ eye, setEye ] = useState<Boolean>(true);
    const [ firstLoad, setFirstLoad ] = useState<Boolean>(false);
    const [queryParams, setQueryParams] = useState<any>({
        search: '',
        code: '',
        user_id: '',
        date: '',
        date2: '',
        year: '',
        month: '',
        ordering_field: 'date',
        ordering: 'DESC',
        page: 1,
        page_size: 10,
        employee_full_name: '',
        is_view_subordinate: false,
        sub_code: '',
        sub_full_name: ''
    });
    const [data, setData] = useState<ApiResponse<any>>(initialResponseData);
    const { authJwt } = useAuth();
    const isAdmin = authJwt?.role === 'ADMIN';
    const { loaderComponent, isLoading, startLoading, stopLoading } = useSpinnerLoader();

    const isEdit = searchParams.get('isEdit');
    const id = searchParams.get('id');
    const userId = !isEdit && !id ? authJwt?.id : id && parseInt(id) || null;
    const currentUserId = authJwt?.id;
    let employeeTmp: any = null;
    const [ employee, setEmployee ] = useState<any>(null);

    // ====================

    const [dataMain, setDataMain] = useState<any>(null);
    const [dataExport, setDataExport] = useState<any>({});
    const [dataExportAll, setDataExportAll] = useState({ count: 0, row: [] });
    const [ refButtonExport, setRefButtonExport ] = useState<any>(null);
    const [queryParamsExport, setQueryParamsExport] = useState({
        user_id: '',
        // month: month,
        month: parseInt(Moment().format('MM')),
        //year: year - 543,
        year: parseInt(Moment().format('YYYY')),
    });

    const headers: Header[] = [
        {
            label: 'พนักงาน',
            field: 'code',
            order: null,
            isOrder: true,
        },
        {
            label: 'วันที่',
            field: 'date',
            order: null,
            isOrder: true,
        },
        {
            label: 'เข้างาน',
            field: 'scan_min_time',
            order: null,
            isOrder: true,
        },
        {
            label: 'ออกงาน',
            field: 'scan_max_time',
            order: null,
            isOrder: true,
        },
        {
            label: '',
            field: '',
            order: null,
            isOrder: false,
        }
    ];

    const fetchEmployeeMe = async () => {
        startLoading();
        try {
            const employee2 = await apiEmployeeService.getEmployeeMe();
            setEmployee(employee2);
            employeeTmp = employee2;
            if(!isAdmin) {
                queryParams.code = employee2.code;
                queryParams.user_id = employee2.user_id;
            }
        } catch (error) {
            const err = error as AxiosError;
            notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
        } finally {
            stopLoading();
        }
    };

    const fetchDataExport = async () => {
        try {
        //   if (queryParamsExport.user_id == '') {
        //     setDataExportAll(await ReportInOutLeaveService.getReportInOutLeaveDetail_Export(queryParamsExport));
        //     setDataExport({ count: 0, row: [] });
        //   } else {
            setDataExport(await ReportInOutLeaveService.getReportInOutLeaveDetail_Export(queryParamsExport))
            setDataExportAll({ count: 0, row: [] });
          //}
        } catch (error) {
          const err = error as AxiosError;
          notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
        }
      };

      const fetchDataMain = async () => {
        //if(!isUserLoaded) { return; }
    
        //setQueryParamsExport({ ...queryParamsExport, user_id: '-' })

        //console.log('XXX', queryParams.year + '-' + queryParams.month + '-13');
    
        startLoading();
        try {
          const result = await ReportInOutLeaveService.getReportInOutLeaveDetailMonth(isAdmin ? queryParams : {
            // division_group_office_name: queryParams.division_group_office_name,
            // structure_departments_name: queryParams.structure_departments_name,
            // position_name: queryParams.position_name,
            //date: Moment(queryParams.year + '-' + queryParams.month + '-13').format('YYYY-MM-DD'), //queryParams.date,
            //date: '2024-05-13',
            // search: queryParams.search,
            user_id: queryParams.user_id,
            year: queryParams.year,
            month: queryParams.month,
            page: queryParams.page,
            page_size: queryParams.page_size
          });
    
        //   {
        //     result.rows?.map(async (data1: any) => {
        //       data1.userData = null;
        //       try {
        //         data1.userData = await apiEmployeeService.getEmployeeDetail(data1.user_id);
        //       } catch(e) {
        //         const err = e as AxiosError;
        //         if(err.request.status != 404) {
        //           console.log(err);
        //         }
        //       }
              
        //     });
        //   }
    
          setDataMain(result);

          setTimeout(() => {
            if(refButtonExport) { refButtonExport.click(); }

            stopLoading()
            setQueryParamsExport({ ...queryParamsExport, user_id: '' })
        }, 1500);
    
        } catch (error) {
          const err = error as AxiosError;
          notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
        } finally {
          stopLoading();
        }
      };

    const createSystemLog = async (data: any) => {
        try {
            await SystemLogService.create(data);
        } catch (error) {
            const err = error as any;
            notificationService.dangerNotification('ระบบขัดข้อง', err.message);
        }
    }

    useEffect(() => {
        createSystemLog({
            employee_id: authJwt?.employee_id,
            module: 'employee-checkin-report',
            action: 'view',
        });
    }, []);

    useEffect(() => {
        let f = async () => {
            await fetchEmployeeMe();
            await fetchData();
        }
        f();
    }, [queryParams.ordering, queryParams.page_size, queryParams.page]);

    useEffect(() => {
        queryParamsExport.user_id !== '' ?
          fetchDataExport()
          :
          setDataExport({ count: 0, row: [] }); setDataExportAll({ count: 0, row: [] });
      }, [
        queryParamsExport.user_id,
    ]);

    // useEffect(() => {
    //     fetchDataMain();
    // }, []);

    const fetchData = async () => {
        startLoading();
        try {
            let code = queryParams.code;
            let name = queryParams.employee_full_name;
            let is_view_subordinate = queryParams.is_view_subordinate;
            if(!isAdmin) {
                if(is_view_subordinate) {
                    code = queryParams.sub_code;
                    name = queryParams.sub_full_name;
                }
            }
            const response = await apiService().get(`${process.env.REACT_APP_API_URI}/employees-check-ins/v2/get-all-users-for-everyday`, {
                params: {
                    code: code,
                    // date: Moment(queryParams.date).isValid() ? Moment(queryParams.date).format('YYYY-MM-DD') : '',
                    // date2: Moment(queryParams.date2).isValid() ? Moment(queryParams.date2).format('YYYY-MM-DD') : '',
                    year: queryParams.year && eye ? queryParams.year : '',
                    month: queryParams.month && eye ? queryParams.month : '',
                    page: queryParams.page,
                    limit: queryParams.page_size,
                    ordering_field: queryParams.ordering_field,
                    ordering: queryParams.ordering,
                    employee_full_name: name,
                    is_view_subordinate: is_view_subordinate ? 1:0
                }
            });
            // for(let i=0; i<response.data.rows.length; ++i) {
            //     let obj = response.data.rows[i];
            //     obj.key = obj.user_id + '-' + obj.date;
            // }
            setData(response.data);
            setFirstLoad(true);
        } catch (error) {
            const err = error as AxiosError;
            notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
        } finally {
            stopLoading();
        }
    };

    const toggleEye = () => {
        setEye(!eye);
    }

    // useEffect(() => {
    //     if(firstLoad) {
    //         fetchData();
    //     }
    // }, [eye]);

    const download1 = () => {
        alert('WIP');
    }

    const onChangePageSize = (page_size: number) => {
        setQueryParams({
            ...queryParams,
            page_size: page_size
        });
    };

    const onChangePage = (page: number) => {
        setQueryParams({
            ...queryParams,
            page: page
        });
    };
    const tableRowNoData = () => {
        if (data.rows.length === 0) {
            return <TableRowNoData colspan={4} />;
        }
        return null;
    };

    const onChangeCode = (e: ChangeEvent<HTMLInputElement>) => {
        // setQueryParams((prevState: any) => {
        //     return {...prevState, code: e.target.value};
        // });
        setQueryParams({
            ...queryParams,
            page: 1,
            code: e.target.value
        });
    };

    const onChangeSubCode = (e: ChangeEvent<HTMLInputElement>) => {
        setQueryParams({
            ...queryParams,
            page: 1,
            sub_code: e.target.value
        });
    };

    const onChangeFullname = (e: ChangeEvent<HTMLInputElement>) => {
        // setQueryParams((prevState: any) => {
        //     return {...prevState, code: e.target.value};
        // });
        setQueryParams({
            ...queryParams,
            page: 1,
            employee_full_name: e.target.value
        });
    };

    const onChangeSubFullname = (e: ChangeEvent<HTMLInputElement>) => {
        setQueryParams({
            ...queryParams,
            page: 1,
            sub_full_name: e.target.value
        });
    };

    const onChangeIsViewSubOrdinate = (e: ChangeEvent<HTMLInputElement>) => {
        setQueryParams({
            ...queryParams,
            page: 1,
            is_view_subordinate: e.target.checked
        });
    };

    const onChangeDate = (e: ChangeEvent<HTMLInputElement>) => {
        // setQueryParams((prevState: any) => {
        //     return {...prevState, date: e.target.value};
        // });
        setQueryParams({
            ...queryParams,
            page: 1,
            date: e.target.value
        });
    };

    const onChangeDate2 = (e: ChangeEvent<HTMLInputElement>) => {
        setQueryParams({
            ...queryParams,
            page: 1,
            date2: e.target.value
        });
    };

    const onChangeMonth = (e: ChangeEvent<HTMLSelectElement>) => {
        setQueryParams({
            ...queryParams,
            page: 1,
            month: e.target.value
        });
    };

    const onChangeYear = (e: ChangeEvent<HTMLInputElement>) => {
        setQueryParams({
            ...queryParams,
            page: 1,
            year: e.target.value
        });
    };

    const onChangeOrder = (orderData: OrderData) => {
        setQueryParams({
            ...queryParams,
            ordering_field: orderData.field,
            ordering: orderData.order,
        });
    };

    // ===========================================================

    const thaiMonths = [
        'มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน',
        'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'
      ];
    
      const getThaiDayName = (dateString: string) => {
        const date = new Date(dateString);
        const daysInThai = ['อา.', 'จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.'];
        return daysInThai[date.getDay()];
      };
    
      const fDaysInMonth = (month: any, year: any) => {
        return new Date(year, month, 0).getDate();
      }
    
      //let daysInMonth = fDaysInMonth(month, year-543);
      let daysInMonth = fDaysInMonth(parseInt(queryParams.month), parseInt(queryParams.year));
      let daysInMonthArr: any = [];
      for(let i=0; i<daysInMonth; ++i) {
        daysInMonthArr[i] = i+1;
      }
      // console.log('daysInMonth', daysInMonth);
    
      const restDayList = [
        { date: '2024-01-01' },
        { date: '2024-02-24' },
        { date: '2024-02-26' },
        { date: '2024-04-06' },
        { date: '2024-04-08' },
        { date: '2024-04-12' },
        { date: '2024-04-13' },
        { date: '2024-04-14' },
        { date: '2024-04-15' },
        { date: '2024-04-16' },
        { date: '2024-05-01' },
        { date: '2024-05-04' },
        { date: '2024-05-06' },
        { date: '2024-05-10' },
        { date: '2024-06-03' },
        { date: '2024-06-14' },
        { date: '2024-07-20' },
        { date: '2024-07-21' },
        { date: '2024-07-22' },
        { date: '2024-07-28' },
        { date: '2024-07-29' },
        { date: '2024-08-05' },
        { date: '2024-08-12' },
        { date: '2024-10-13' },
        { date: '2024-10-14' },
        { date: '2024-10-23' },
        { date: '2024-12-05' },
        { date: '2024-12-10' },
        { date: '2024-12-30' },
        { date: '2024-12-31' }
      ];

    // let today = Moment('2024-05-15');
    let today = Moment();

    const PDF: React.FC<{ data: any }> = ({ data }) => (
        <Document>
        <Page size="A4" style={{ padding: 10 }}>
            <View>
            <Text style={{ fontFamily: 'kanit-regular', fontSize: 13, textAlign: 'center', marginBottom: 3 }}>
                องค์การตลาดเพื่อเกษตรกร
            </Text>

            <Text style={{ fontFamily: 'kanit-regular', fontSize: 13, textAlign: 'center', marginBottom: 3 }}>
                รายงานการปฏิบัติงานของพนักงาน
            </Text>

            <Text style={{ fontFamily: 'kanit-regular', fontSize: 12, textAlign: 'center', marginBottom: 10 }}>
                {
                (() => {
                    let html = '';

                    if(employee) {
                        employee.job_positions.filter((data2: any) => { return data2.is_currently }).map((data2: any) => {
                            if(data2.division_group_office_name) {
                                html = 'กอง ' + data2.division_group_office_name + ' ';
                            }
                        });
                    }
                    
                    return html;
                    // กองทรัพยากรบุคคล
                })()
                }
                {thaiMonths[parseInt(queryParams.month) - 1]} {parseInt(queryParams.year)+543}
            </Text>

            <View style={{ marginBottom: 10 }}>
                <View style={{ flexDirection: 'row' }}>
                <Text style={{ fontFamily: 'kanit-regular', width: '12%', fontSize: 11, textAlign: 'left', marginLeft: 5 }}>
                    รหัสพนักงาน
                </Text>
                <Text style={{ fontFamily: 'kanit-regular', width: '25%', fontSize: 11, textAlign: 'left', marginLeft: 5 }}>
                    ชื่อ-นามสกุล
                </Text>
                <Text style={{ fontFamily: 'kanit-regular', width: '10%', fontSize: 11, textAlign: 'left', marginLeft: 5 }}>
                    วันที่
                </Text>
                <Text style={{ fontFamily: 'kanit-regular', width: '8%', fontSize: 11, textAlign: 'left', marginLeft: 5 }}>
                    เวลาเข้า
                </Text>
                <Text style={{ fontFamily: 'kanit-regular', width: '13%', fontSize: 11, textAlign: 'left', marginLeft: 5 }}>
                    
                </Text>
                <Text style={{ fontFamily: 'kanit-regular', width: '8%', fontSize: 11, textAlign: 'left', marginLeft: 5 }}>
                    เวลาออก
                </Text>
                <Text style={{ fontFamily: 'kanit-regular', width: '13%', fontSize: 11, textAlign: 'left', marginLeft: 5 }}>
                    
                </Text>
                </View>

                {/* {
                    (() => {
                        console.log('================');
                        console.log('queryParamsExport.user_id >', queryParamsExport.user_id, '<');
                        console.log('queryParamsExport.user_id >' + queryParamsExport.user_id + '<');
                        console.log('employee.user_id >', employee?.user_id, '<');
                        console.log('dataMain', dataMain);
                        console.log('dataExport', dataExport);
                        console.log('data', data);
                        return '';
                    })()
                } */}

                {
                    (() => {
                        return <>
                            <View key={1} style={{ flexDirection: 'row' }}>
                            <Text style={{ fontFamily: 'kanit-200', width: '12%', fontSize: 10, textAlign: 'left', marginLeft: 5 }}>
                                {employee?.code}
                            </Text>
                            <Text style={{ fontFamily: 'kanit-200', width: '25%', fontSize: 10, textAlign: 'left', marginLeft: 5 }}>
                                {employee?.prefix_name_th + employee?.firstname_th + " " + employee?.lastname_th}
                            </Text>
                            </View>

                            { daysInMonthArr.map((day: any) => {
                            let found = false;
                            let html: any;
                            dataExport?.row[0]?.data?.map((data2: any, index2: any) => {
                                let d = Moment(data2.date);
                                if(parseInt(Moment(data2.date).format('DD')) == day) {
                                    //console.log('aaa', day, data2.date, Moment(data2.date).format('DD'));
                                    let time1 = data2.first_check_in_time.substring(11, 19);
                                    let time2 = data2.last_check_in_time.substring(11, 19);
                                    let timePos1 = time1 < '12:00:00' ? time1 : time2 < '12:00:00' ? time2 : '';
                                    let timePos2 = time2 >= '12:00:00' ? time2 : time1 >= '12:00:00' ? time1 : '';
                                    let isLate = timePos1 && timePos1 >= '08:46:00' && timePos1 < '10:00:00';
                                    let isMissFirstHalf = timePos1 && timePos1 >= '10:00:00' && timePos1 < '12:00:00';
                                    let isOutEarly = timePos2 && timePos2 < '16:30:00';
                                    let isMissed = false;
                                    let isLeaveFirstHalfMiss = false;
                                    let leaveFirstHalf = false;
                                    let leaveLastHalf = false;
                                    let leaveFull = false;
                                    let leaveTypeName = '';
                                    let isRestDay = restDayList.find((v: any) => v.date == Moment(data2.date).format('YYYY-MM-DD'));
                                    (() => {
                                        dataMain.rows.filter((data3: any) => { return data3.user_id == employee?.user_id }).map((data3: any) => {
                                            data3.leave_detail_days.filter((data4: any) => { return data4.day == day }).map((data4: any) => {
                                                if(data4.status == 'approved') {
                                                    if(data4.total_leave_time == 'ครึ่งวันเช้า') { leaveFirstHalf = true; }
                                                    else if(data4.total_leave_time == 'ครึ่งวันบ่าย') { leaveLastHalf = true; }
                                                    else if(data4.total_leave_time == 'เต็มวัน') { leaveFull = true; }
                                                    if(data4.leave_type_name) {
                                                        leaveTypeName = data4.leave_type_name;
                                                        if(leaveFirstHalf) { leaveTypeName += ' (เช้า)'; }
                                                        else if(leaveLastHalf) { leaveTypeName += ' (บ่าย)'; }
                                                    }
                                                }
                                            });
                                        });
                                    })();
                                    if(leaveFirstHalf) {
                                        timePos1 = time1 < '13:00:00' ? time1 : time2 < '13:00:00' ? time2 : '';
                                        isLeaveFirstHalfMiss = timePos1 >= '13:00:00';
                                        if(isLeaveFirstHalfMiss) { isMissed = true; }
                                    } else {
                                        if(!timePos1) { isMissed = true; }
                                    }
                                    found = true;
                                    html = <View key={index2} style={{ flexDirection: 'row' }}>
                                        <View style={{ width: '37%', marginLeft: 10 }}></View>
                                        <Text style={{ fontFamily: 'kanit-200', width: '10%', fontSize: 10, textAlign: 'left', marginLeft: 5 }}>
                                        {getThaiDayName(data2.date) + " " + Moment(data2.date).format('DD')}
                                        </Text>
                                        <Text style={{ fontFamily: 'kanit-200', width: '8%', fontSize: 10, textAlign: 'left', marginLeft: 5 }}>
                                        {/* {Moment(data2.first_check_in_time).locale("th").format('HH:mm:ss')} */}
                                        { timePos1 ? timePos1 : '-' }
                                        </Text>
                                        <Text style={{ fontFamily: 'kanit-200', width: '13%', fontSize: 10, textAlign: 'left', marginLeft: 5 }}>
                                        {
                                            (() => {
                                            let html2: any = '';

                                            if(d.isSameOrBefore(today)) {
                                                if(isRestDay) {
                                                    html2 += ` วันหยุดนักขัตฤกษ์`;
                                                } else {
                                                    if(!leaveFull) {
                                                        if(isLate) {
                                                            html2 += ` สาย`;
                                                        } else if(isMissFirstHalf) {
                                                            html2 += ` ขาดครึ่งวัน`;
                                                        } else if(isLeaveFirstHalfMiss || isMissed) {
                                                            html2 += ` ขาดงาน`;
                                                        }
                                                    }
                                                }
                                            }
                                            
                                            html2 += ` ${ leaveTypeName }`;

                                            // dataMain.rows.filter((data3: any) => { return data3.user_id == data1.user?.id }).map((data3: any) => {
                                            //   data3.leave_detail_days.filter((data4: any) => { return data4.day == day }).map((data4: any) => {
                                            //     // html2 += data4.leave_type_name ? data4.leave_type_name : '-';
                                            //     html2 += data4.leave_type_name ? ' ' + data4.leave_type_name : '';
                                            //   });
                                            // });
                                            html2 = html2.trim();
                                            return html2;
                                            })()
                                        }
                                        </Text>
                                        <Text style={{ fontFamily: 'kanit-200', width: '8%', fontSize: 10, textAlign: 'left', marginLeft: 5 }}>
                                        {/* {Moment(data2.last_check_in_time).locale("th").format('HH:mm:ss')} */}
                                        { timePos2 ? timePos2 : '-' }
                                        </Text>
                                        <Text style={{ fontFamily: 'kanit-200', width: '13%', fontSize: 10, textAlign: 'left', marginLeft: 5 }}>
                                        {
                                            (() => {
                                            let html2: any = '';

                                            if(d.isSameOrBefore(today)) {
                                                if(isOutEarly && !leaveLastHalf) {
                                                    html2 += ` ออกก่อนเวลา`;
                                                }
                                            }

                                            html2 = html2.trim();
                                            return html2;
                                            })()
                                        }
                                        </Text>
                                    </View>
                                }

                                if(!found) {
                                    let d = Moment().set('year', parseInt(queryParams.year)).set('month', parseInt(queryParams.month)-1).set('date', day);
                                    let leaveTypeName = '';
                                    let leaveFull = false;
                                    (() => {
                                        dataMain.rows.filter((data3: any) => { return data3.user_id == employee?.user_id }).map((data3: any) => {
                                            data3.leave_detail_days.filter((data4: any) => { return data4.day == day }).map((data4: any) => {
                                                //data3.leave_detail.filter((data4: any) => { return parseInt(Moment(data4.start_date).format('DD')) == day }).map((data4: any) => {
                                                //if(data4.total_leave_time == 'ครึ่งวันเช้า') { leaveFirstHalf = true; }
                                                if(data4.status == 'approved') {
                                                    if(data4.total_leave_time == 'เต็มวัน') { leaveFull = true; }
                                                    if(data4.leave_type_name) { leaveTypeName = data4.leave_type_name; }
                                                }
                                            });
                                        });
                                    })();
                                    let isRestDay = restDayList.find((v: any) => v.date == d.format('YYYY-MM-DD'));
                                    html = <View key={day} style={{ flexDirection: 'row' }}>
                                    <View style={{ width: '37%', marginLeft: 10 }}></View>
                                    <Text style={{ fontFamily: 'kanit-200', width: '10%', fontSize: 10, textAlign: 'left', marginLeft: 5 }}>
                                    {getThaiDayName(d.format('YYYY-MM-DD')) + " " + d.format('DD')}
                                    </Text>
                                    <Text style={{ fontFamily: 'kanit-200', width: '8%', fontSize: 10, textAlign: 'left', marginLeft: 5 }}>
                                    {'-'}
                                    </Text>
                                    <Text style={{ fontFamily: 'kanit-200', width: '13%', fontSize: 10, textAlign: 'left', marginLeft: 5 }}>
                                    {
                                        (() => {
                                        let html2: any = '';
    
                                        if(d.isSameOrBefore(today)) {
                                            if(isRestDay) {
                                                html2 += ` วันหยุดนักขัตฤกษ์`;
                                            } else {
                                                if(!leaveFull) {
                                                    if([1,2,3,4,5].includes(d.get('day'))) {
                                                        html2 += ` ขาดงาน`;
                                                    }
                                                }
                                            }
                                        }
                                        
    
                                        html2 += ` ${ leaveTypeName }`;
                                        html2 = html2.trim();
                                        return html2;
                                        })()
                                    }
                                    </Text>
                                    <Text style={{ fontFamily: 'kanit-200', width: '8%', fontSize: 10, textAlign: 'left', marginLeft: 5 }}>
                                    {'-'}
                                    </Text>
                                    <Text style={{ fontFamily: 'kanit-200', width: '13%', fontSize: 10, textAlign: 'left', marginLeft: 5 }}>
                                    {''}
                                    </Text>
                                </View>
                                }
                            })
                            
                            //console.log('AAA', day, found);
                            return html;
                            }) }

                            

                            <View style={{ marginTop: 10 }} />
                        </>
                    })()
                }
            </View>
            </View>
        </Page>
        </Document>
    );

    // const PDF: React.FC<{ data: any }> = ({ data }) => (
    //     <Document>
    //       <Page size="A4" style={{ padding: 10 }}>
    //         <View>
    //           <Text style={{ fontFamily: 'kanit-regular', fontSize: 13, textAlign: 'center', marginBottom: 3 }}>
    //             องค์การตลาดเพื่อเกษตรกร
    //           </Text>
    
    //           <Text style={{ fontFamily: 'kanit-regular', fontSize: 13, textAlign: 'center', marginBottom: 3 }}>
    //             รายงานการปฏิบัติงานของพนักงาน
    //           </Text>
    //         </View>
    //       </Page>
    //     </Document>
    //   );

    // ===========================================================

    return <div>
        <div className="d-flex justify-content-between py-4">
            <h2 className="text-mof-primary m-0">รายงานการเข้าออกงาน</h2>
        </div>
        <div className="row">
            {
                (() => {
                    if(isAdmin) {
                        return <div className="col-sm-12 col-md-2">
                            <label className="form-label">รหัสผู้ใช้</label>
                            <input className="form-control" type="text" value={queryParams.code}
                                onChange={onChangeCode} name="user_id"/>
                        </div>
                    }
                    return '';
                })()
            }
          {
            (() => {
              if(isAdmin) {
                return <div className="col-sm-12 col-md-2">
                  <label className="form-label">ชื่อ-นามสกุล</label>
                  <input className="form-control" type="text" value={queryParams.employee_full_name}
                         onChange={onChangeFullname} name="employee_full_name"/>
                </div>
              }
              return '';
            })()
          }
            {/* <div className="col-sm-12 col-md-2">
                <label className="form-label">วันที่เริ่ม</label>
                <input className="form-control" type="date" value={queryParams.date}
                       onChange={onChangeDate} name="search"/>
            </div>
            <div className="col-sm-12 col-md-2">
                <label className="form-label">ถึง</label>
                <input className="form-control" type="date" value={queryParams.date2}
                       onChange={onChangeDate2} name="search"/>
            </div> */}
            <div className="col-sm-12 col-md-2">
                <label className="form-label">เดือน</label>
                <select className="form-control" onChange={onChangeMonth} name="search">
                    <option value="">-- เลือก --</option>
                    <option value="1">มกราคม</option>
                    <option value="2">กุมภาพันธ์</option>
                    <option value="3">มีนาคม</option>
                    <option value="4">เมษายน</option>
                    <option value="5">พฤษภาคม</option>
                    <option value="6">มิถุนายน</option>
                    <option value="7">กรกฎาคม</option>
                    <option value="8">สิงหาคม</option>
                    <option value="9">กันยายน</option>
                    <option value="10">ตุลาคม</option>
                    <option value="11">พฤศจิกายน</option>
                    <option value="12">ธันวาคม</option>
                </select>
                {/* <input className="form-control" type="text" value={queryParams.month}
                       onChange={onChangeMonth} name="search"/> */}
            </div>
            <div className="col-sm-12 col-md-2">
                <label className="form-label">ปี</label>
                <input className="form-control" type="text" value={queryParams.year}
                       onChange={onChangeYear} name="search"/>
            </div>
            {
                (() => {
                    if(!isAdmin) {
                        return <div className="col-sm-12 col-md-2">
                            <div style={{ height: '38px' }}></div>
                            <Form.Check
                                inline={ true }
                                type="checkbox"
                                label="ดูเวลาเข้า-ออก ผู้ใต้บังคับบัญชา"
                                id="test-1"
                                value="1"
                                checked={queryParams.is_view_subordinate == '1'}
                                onChange={onChangeIsViewSubOrdinate}
                            />
                        </div>
                    }
                })()
            }
            
            {
                (() => {
                    if(!isAdmin) {
                        return <div className="col-sm-12 col-md-2">
                            <label className="form-label">รหัสผู้ใต้บังคับบัญชา</label>
                            <input className="form-control" type="text" value={queryParams.sub_code}
                                onChange={onChangeSubCode}/>
                        </div>
                    }
                    return '';
                })()
            }
            {
                (() => {
                    if(!isAdmin) {
                        return <div className="col-sm-12 col-md-2">
                        <label className="form-label">ชื่อ-นามสกุล ผู้ใต้บังคับบัญชา</label>
                        <input className="form-control" type="text" value={queryParams.sub_full_name}
                                onChange={onChangeSubFullname}/>
                        </div>
                    }
                    return '';
                })()
            }
            <div className="col-sm-12 col-md-2">
                <div style={{ height: '31px' }}></div>
                <Button variant="primary" type="button" className="d-inline-block px-4" onClick={fetchData}>
                    <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                </Button>
                <div style={{ display: 'inline-block', width: '3px' }}></div>
                <Button variant="primary" type="button" className="d-inline-block" onClick={toggleEye}>
                    <FontAwesomeIcon icon={eye ? faEye : faEyeLowVision}></FontAwesomeIcon>
                </Button>
                {
                    (() => {
                        if(!isAdmin) {
                            return <>
                                <div style={{ display: 'inline-block', width: '3px' }}></div>

                                <Button type="button" variant="primary" className="d-inline-block" onClick={() => {

                                    if(!queryParams.month || !queryParams.year) { alert('กรุณาเลือก เดือน และ ปี'); return; }

                                    let user_id = '';
                                    if(!isAdmin) {
                                        user_id = employee.user_id;
                                    }

                                    setQueryParamsExport({
                                        ...queryParamsExport,
                                        user_id: user_id,
                                        month: queryParams.month,
                                        year: queryParams.year
                                    });

                                    fetchDataMain();

                                    // startLoading();

                                    // setTimeout(() => {
                                        
                                    //     // if (refButtonExport.current[index]) {
                                    //     //     refButtonExport.current[index]?.click();
                                    //     // }

                                    //     if(refButtonExport) {
                                    //         refButtonExport.click();
                                    //     }

                                    //     stopLoading()
                                    //     setQueryParamsExport({ ...queryParamsExport, user_id: '' })
                                    // }, 3000);
                                }}>
                                    <FontAwesomeIcon icon={faFileExport} />
                                </Button>
                                <PDFDownloadLink document={ queryParamsExport?.user_id && dataMain ? <PDF data={queryParamsExport?.user_id ? <PDF data={dataExport} /> : <></>} /> : <></>} fileName={`รายงานการปฏิบัติงานของพนักงาน.pdf`}>
                                    {({ blob, url, loading, error }) => (
                                        <span ref={(el: any) => setRefButtonExport(el)}></span>
                                    )}
                                </PDFDownloadLink>
                                {/* <PDFDownloadLink document={queryParamsExport.user_id !== '' && queryParamsExport.user_id !== '-' ? <PDF data={dataExport} /> : <></>} fileName={`รายงานสถิติเข้า-ออกงานและการลา (${data.prefix_name_th + data.firstname_th + " " + data.lastname_th}).pdf`}>
                                    {({ blob, url, loading, error }) => (
                                    <span ref={(el: any) => refButtonExport.current[index] = el}></span>
                                    )}
                                </PDFDownloadLink> */}

                                {/* <Button variant="primary" type="button" className="d-inline-block" onClick={download1}>
                                    <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                                </Button> */}
                            </>
                        }
                    })()
                }
                
            </div>
        </div>
        <div className="overflow-auto mt-3">
            <Table striped bordered hover>
                <thead>
                    <TableHeaderOrdering headers={headers} onChangeOrder={onChangeOrder} isShow={true} />
                </thead>
                <tbody>
                    {
                        data.rows.map((data, index) => {
                            let name = '(' + data.code + ') ' + data.firstname_th + ' ' + data.lastname_th;
                            if(!data.code && !data.firstname_th && !data.lastname_th) { name = `ไม่พบผู้ใช้งาน`; }
                            return <tr key={ index }>
                                <td>{name}</td>
                                <td>{data.date && Moment(data.date).isValid() ? Moment(data.date).format('DD/MM/YYYY') : '-'}</td>
                                <td>{data.scan_in_time ? data.scan_in_time : '-'}</td>
                                <td>{data.scan_out_time ? data.scan_out_time : '-'}</td>
                                <td></td>
                            </tr>;
                        })
                    }
                    {tableRowNoData()} 
                </tbody>
            </Table>
        </div>
        <div className="">
            <PaginationForm current_page={queryParams.page} count={data.count} page_size={queryParams.page_size}
                onChangePageSize={onChangePageSize} onChangePage={onChangePage} />
        </div>
        {loaderComponent}
    </div>;
}

export default EmployeeCheckInsListPage;

function useRef<T>(arg0: never[]) {
    throw new Error('Function not implemented.');
}
