import React, { ChangeEvent, FC, useState } from 'react';
import { Button, Form, Image } from 'react-bootstrap';
import useSpinnerLoader from '../../../shared/components/spinner-loader';
import { ResetPassword } from '../../../shared/models/authentication.model';
import { notificationService } from '../../../shared/services/notification-service';
import { apiAuthentication } from '../../../shared/services/authentication-service';
import { Link, useNavigate } from 'react-router-dom';

const ForgetPasswordPage: FC = () => {
  const {loaderComponent, isLoading, startLoading, stopLoading} = useSpinnerLoader();
  const [submitted, setSubmitted] = useState(false);
  const [form, setForm] = useState<ResetPassword>({
    id_card: '',
  });
  const [showMessage, setShowMessage] = useState('');
  const [isSubmit, setIsSubmit] = useState(false);

  const navigate = useNavigate();

  const handleOnChangeIdCard = (e: ChangeEvent<HTMLInputElement>) => {
    setForm(data => {
      return {...data, id_card: e.target.value};
    });
  };

  const onResetPassword = async (event: any) => {
    event.preventDefault();
    setSubmitted(true);
    setShowMessage('');
    setIsSubmit(true);

    if (
      !form.id_card || form.id_card.length !== 13
    ) {
      notificationService.warningNotification('กรุณากรอกหมายเลขบัตรประชาชนในระบบ', '');
      setSubmitted(false);
      setIsSubmit(false);
      return;
    }

    try {
      const res = await apiAuthentication.onResetPassword(form);
      //let res = { email: 'test' }
      notificationService.successNotification('ลิงก์รีเซ็ตรหัสผ่านจะส่งไปที่อีเมล', '');
      setShowMessage(res.email);
      setTimeout(() => {
        navigate('/');
      }, 3000);
    } catch (error) {
      const err = error as any;
      if (err.response?.status === 400) {
        notificationService.dangerNotification('ไม่พบข้อมูล', err.response.data.message);
      } else {
        notificationService.dangerNotification('ระบบขัดข้อง', err.message);
      }
      setSubmitted(false);
      setIsSubmit(false);
    } finally {
      stopLoading();
    }
  };

  return <Form noValidate validated={submitted} className="mb-5" onSubmit={onResetPassword}>
    {loaderComponent}
    <div className="container pt-5">
      <div className="m-auto login-logo">
        <Image src="static/ORTORKOR-LOGO.png" alt="logo" className="w-100"/>
      </div>
      <div className="bg-white shadow rounded px-sm-5 px-3 py-5 reset-password-page m-auto mt-4">
        <h4 className="text-mof-primary">
          ลืมรหัสผ่าน
        </h4>
        <div className="row mt-3">
          <div className="col-sm-12 col-md-12">
            <label className="form-label required" htmlFor="id_card">หมายเลขบัตรประชาชน</label>
            <div className="input-group">
              <input type="text" className="form-control" id="id_card" name="id_card"
                     required maxLength={13}
                     pattern=".{13,13}"
                     autoComplete="off"
                     value={form.id_card} onChange={handleOnChangeIdCard}/>
              <div className="invalid-feedback">
                ** ระบุหมายเลขบัตรประชาชนเพื่อยืนยันตัวตน
              </div>
            </div>
          </div>
        </div>
        {
          !!showMessage ? <div className="row mt-3">
            <div className="col-sm-12 col-md-12">
              <p className="m-0 text-secondary">ลิงก์รีเซ็ตรหัสผ่านจะส่งไปที่อีเมล</p>
              <p className="text-secondary">{showMessage}</p>
            </div>
          </div> : <></>
        }
        <div className="row mt-3">
          <div className="col-sm-12 col-md-4">
            <Button variant="primary" type="submit" disabled={isSubmit}
                    className="d-block w-100 btn-action-height btn-mof-primary">
              รีเซ็ตรหัสผ่าน
            </Button>
          </div>
        </div>
      </div>
    </div>
  </Form>;
};

export default ForgetPasswordPage;
