import React, { FC, useRef, useEffect, useState, ChangeEvent } from 'react';
import useSpinnerLoader from '../../../../shared/components/spinner-loader';
import { Table } from 'react-bootstrap';
import { notificationService } from '../../../../shared/services/notification-service';
import { apiEmployeeService } from '../../../../shared/services/employee-service';
import { initialResponseData } from "../../../../shared/utils/constants";
import { ReportInOutLeaveService } from "../../../../shared/services/Report/ReportInOutLeave/report-in-out-leave";
import PaginationForm from "../../../../shared/components/molecules/commons/pagination-form";
import TableRowNoData from "../../../../shared/components/molecules/commons/table-row-no-data";
import { AxiosError } from "axios/index";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import InputSelect from "../../../../shared/components/atoms/input-select";
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import Moment from "moment";
import { useAuth } from '../../../../shared/authentications/auth-context';

import {
  fetchDepartmentsOfficeNameOptions,
  fetchDivisionGroupOfficeNameOptions,
  fetchDepartmentsNameOptions,
  fetchPositionNameOptions,
  fetchLeaveTypeOptions,

  department_offices_name_Options,
  division_group_office_name_Options,
  departments_name_Options,
  position_name_Options,
  leave_type_Options,
} from '../../OptionsDropDown';

import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { Document, Page, Text, View, PDFDownloadLink, Font } from '@react-pdf/renderer';
Font.register({ family: 'kanit-regular', format: "truetype", src: 'https://fonts.gstatic.com/s/kanit/v1/L6VKvM17ZmevDynOiw7H9w.ttf' });
Font.register({ family: 'kanit-200', format: "truetype", src: 'https://fonts.gstatic.com/s/kanit/v1/wfLWkj1C4tYl7MoiFWS3bA.ttf' });

const ReportInOutLeaveDetailPage: FC = () => {
  const { authJwt } = useAuth();
  const isAdmin = authJwt?.role === 'ADMIN';
  const [isUserLoaded, setIsUserLoaded] = useState(false);

  const getEmployeeMe = async () => {
    const response = await apiEmployeeService.getEmployeeMe();

    // if(response.length !== 0) {
    //   let currentJob = response.job_positions.find((v: any) => v.is_currently);
    //   console.log('AAA', currentJob);
    // }

    if(response.length !== 0 && !isAdmin) {
      let arr: Array<any> = response.job_positions.filter((v: any) => v.is_currently);
      let division_group_office_name = arr.at(0)?.division_group_office_name || '';
      setIsUserLoaded(true);
      setQueryParams({
        ...queryParams,
        division_group_office_name: division_group_office_name
      });
    } else {
      setIsUserLoaded(true);
      setQueryParams(queryParams);
    }

  }

  useEffect(() => {
    getEmployeeMe()
  }, []);

  //
  
  // let { day, month, year }: any = useParams();
  // let { day, month, year }: any = useState<any>({
  //   day: parseInt(Moment().format('MM')),
  //   month: parseInt(Moment().format('DD')),
  //   year: parseInt(Moment().format('YYYY')+543)
  // });

  const { loaderComponent, startLoading, stopLoading } = useSpinnerLoader();

  const [data, setData] = useState(initialResponseData);
  const [dataMain, setDataMain] = useState<any>({});
  const [queryParams, setQueryParams] = useState({
    department_offices_name: '',
    division_group_office_name: '',
    structure_departments_name: '',
    position_name: '',
    //date: `${year - 543}-${month}-${day}`,
    date: Moment().format('YYYY-MM-DD'),
    search: '',
    page: 1,
    page_size: 10,
  });

  const fetchData = async () => {
    if(!isUserLoaded) { return; }

    setQueryParamsExport({ ...queryParamsExport, user_id: '-' })

    startLoading();
    try {
      const result = await ReportInOutLeaveService.getReportInOutLeaveDetail(isAdmin ? queryParams : {
        division_group_office_name: queryParams.division_group_office_name,
        structure_departments_name: queryParams.structure_departments_name,
        position_name: queryParams.position_name,
        date: queryParams.date,
        search: queryParams.search,
        page: queryParams.page,
        page_size: queryParams.page_size
      });

      {
        result.rows?.map(async (data1: any) => {
          data1.userData = null;
          try {
            data1.userData = await apiEmployeeService.getEmployeeDetail(data1.user_id);
          } catch(e) {
            const err = e as AxiosError;
            if(err.request.status != 404) {
              console.log(err);
            }
          }
          
        });
      }

      setData(result);
      setDataMain(result);

    } catch (error) {
      const err = error as AxiosError;
      notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
    } finally {
      stopLoading();
    }
  };

  const [dataExportAll, setDataExportAll] = useState({ count: 0, row: [] });

  const refButtonExport = useRef<(HTMLDivElement | null)[]>([]);
  const [dataExport, setDataExport] = useState({ count: 0, row: [] });

  const [queryParamsExport, setQueryParamsExport] = useState({
    user_id: '-',
    // month: month,
    month: parseInt(Moment(queryParams.date).format('MM')),
    //year: year - 543,
    year: parseInt(Moment(queryParams.date).format('YYYY')),
  });

  const fetchDataExport = async () => {
    try {
      if (queryParamsExport.user_id == '') {
        setDataExportAll(await ReportInOutLeaveService.getReportInOutLeaveDetail_Export(queryParamsExport));
        setDataExport({ count: 0, row: [] });
      } else {
        setDataExport(await ReportInOutLeaveService.getReportInOutLeaveDetail_Export(queryParamsExport))
        setDataExportAll({ count: 0, row: [] });
      }
    } catch (error) {
      const err = error as AxiosError;
      notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
    }
  };

  const tableRowNoData = () => {
    if (data.rows?.length === 0) {
      return <TableRowNoData colspan={6 + (leave_type_Options.slice(1).length) + (leave_type_Options.slice(1).length)} />;
    }
    return null;
  };

  const onChangePageSize = (page_size: number) => {
    setQueryParams({
      ...queryParams,
      page_size: page_size
    });
  };

  const onChangePage = (page: number) => {
    setQueryParams({
      ...queryParams,
      page: page
    });
  };

  useEffect(() => {
    fetchData();
  }, [
    queryParams.department_offices_name,
    queryParams.division_group_office_name,
    queryParams.structure_departments_name,
    queryParams.position_name,
    queryParams.page_size,
    queryParams.page,
    isUserLoaded
  ]);

  useEffect(() => {
    queryParamsExport.user_id !== '-' ?
      fetchDataExport()
      :
      setDataExport({ count: 0, row: [] }); setDataExportAll({ count: 0, row: [] });
  }, [
    queryParamsExport.user_id,
  ]);

  useEffect(() => {
    console.log("dataExportAll", dataExportAll)
    console.log("dataExport", dataExport)
  }, [
    dataExportAll, dataExport
  ]);

  useEffect(() => {
    fetchDepartmentsOfficeNameOptions();
    fetchDivisionGroupOfficeNameOptions();
    fetchDepartmentsNameOptions();
    fetchPositionNameOptions();
    fetchLeaveTypeOptions();
  }, []);



  const thaiMonths = [
    'มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน',
    'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'
  ];

  const getThaiDayName = (dateString: string) => {
    const date = new Date(dateString);
    const daysInThai = ['อา.', 'จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.'];
    return daysInThai[date.getDay()];
  };

  const fDaysInMonth = (month: any, year: any) => {
    return new Date(year, month, 0).getDate();
  }

  //let daysInMonth = fDaysInMonth(month, year-543);
  let daysInMonth = fDaysInMonth(Moment(queryParams.date).format('MM'), parseInt(Moment(queryParams.date).format('YYYY')));
  let daysInMonthArr: any = [];
  for(let i=0; i<daysInMonth; ++i) {
    daysInMonthArr[i] = i+1;
  }
  // console.log('daysInMonth', daysInMonth);

  const restDayList = [
    { date: '2024-01-01' },
    { date: '2024-02-24' },
    { date: '2024-02-26' },
    { date: '2024-04-06' },
    { date: '2024-04-08' },
    { date: '2024-04-12' },
    { date: '2024-04-13' },
    { date: '2024-04-14' },
    { date: '2024-04-15' },
    { date: '2024-04-16' },
    { date: '2024-05-01' },
    { date: '2024-05-04' },
    { date: '2024-05-06' },
    { date: '2024-05-10' },
    { date: '2024-06-03' },
    { date: '2024-06-14' },
    { date: '2024-07-20' },
    { date: '2024-07-21' },
    { date: '2024-07-22' },
    { date: '2024-07-28' },
    { date: '2024-07-29' },
    { date: '2024-08-05' },
    { date: '2024-08-12' },
    { date: '2024-10-13' },
    { date: '2024-10-14' },
    { date: '2024-10-23' },
    { date: '2024-12-05' },
    { date: '2024-12-10' },
    { date: '2024-12-30' },
    { date: '2024-12-31' }
  ];

  // let today = Moment('2024-05-15');
  let today = Moment();

  const PDF: React.FC<{ data: any }> = ({ data }) => (
    <Document>
      <Page size="A4" style={{ padding: 10 }}>
        <View>
          <Text style={{ fontFamily: 'kanit-regular', fontSize: 13, textAlign: 'center', marginBottom: 3 }}>
            องค์การตลาดเพื่อเกษตรกร
          </Text>

          <Text style={{ fontFamily: 'kanit-regular', fontSize: 13, textAlign: 'center', marginBottom: 3 }}>
            รายงานการปฏิบัติงานของพนักงาน
          </Text>

          <Text style={{ fontFamily: 'kanit-regular', fontSize: 12, textAlign: 'center', marginBottom: 10 }}>
            {
              (() => {
                let html = '';

                data.row?.map((data1: any, index1: any) => {
                  dataMain.rows.filter((data3: any) => { return data3.user_id == data1.user?.id }).map((data3: any) => {
                    if(data3) {
                      data3.userData?.job_positions.filter((data2: any) => { return data2.is_currently }).map((data2: any) => {
                        if(data2.division_group_office_name) {
                          html = 'กอง ' + data2.division_group_office_name + ' ';
                        }
                      });
                    }
                  });
                });
                
                return html;
                // กองทรัพยากรบุคคล
              })()
            }
            {thaiMonths[parseInt(Moment(queryParams.date).format('MM')) - 1]} {parseInt(Moment(queryParams.date).format('YYYY'))+543}
          </Text>

          <View style={{ marginBottom: 10 }}>
            <View style={{ flexDirection: 'row' }}>
              <Text style={{ fontFamily: 'kanit-regular', width: '12%', fontSize: 11, textAlign: 'left', marginLeft: 5 }}>
                รหัสพนักงาน
              </Text>
              <Text style={{ fontFamily: 'kanit-regular', width: '25%', fontSize: 11, textAlign: 'left', marginLeft: 5 }}>
                ชื่อ-นามสกุล
              </Text>
              <Text style={{ fontFamily: 'kanit-regular', width: '10%', fontSize: 11, textAlign: 'left', marginLeft: 5 }}>
                วันที่
              </Text>
              <Text style={{ fontFamily: 'kanit-regular', width: '8%', fontSize: 11, textAlign: 'left', marginLeft: 5 }}>
                เวลาเข้า
              </Text>
              <Text style={{ fontFamily: 'kanit-regular', width: '13%', fontSize: 11, textAlign: 'left', marginLeft: 5 }}>
                
              </Text>
              <Text style={{ fontFamily: 'kanit-regular', width: '8%', fontSize: 11, textAlign: 'left', marginLeft: 5 }}>
                เวลาออก
              </Text>
              <Text style={{ fontFamily: 'kanit-regular', width: '13%', fontSize: 11, textAlign: 'left', marginLeft: 5 }}>
                
              </Text>
            </View>

            {/* {
              (() => {
                  console.log('================');
                  console.log('queryParamsExport.user_id >', queryParamsExport.user_id, '<');
                  console.log('queryParamsExport.user_id >' + queryParamsExport.user_id + '<');
                  console.log('dataMain', dataMain);
                  console.log('dataExport', dataExport);
                  console.log('data', data);
                  return '';
              })()
            } */}

            {data.row?.map((data1: any, index1: any) => {
              return <>
                <View key={index1} style={{ flexDirection: 'row' }}>
                  <Text style={{ fontFamily: 'kanit-200', width: '12%', fontSize: 10, textAlign: 'left', marginLeft: 5 }}>
                    {data1.user?.code}
                  </Text>
                  <Text style={{ fontFamily: 'kanit-200', width: '25%', fontSize: 10, textAlign: 'left', marginLeft: 5 }}>
                    {data1.user?.prefix_name_th + data1.user?.firstname_th + " " + data1.user?.lastname_th}
                  </Text>
                </View>

                { daysInMonthArr.map((day: any) => {
                  let found = false;
                  let html: any;
                  data1.data?.map((data2: any, index2: any) => {
                    let d = Moment(data2.date);
                    if(parseInt(Moment(data2.date).format('DD')) == day) {
                      //console.log('aaa', day, data2.date, Moment(data2.date).format('DD'));
                      let time1 = data2.first_check_in_time.substring(11, 19);
                      let time2 = data2.last_check_in_time.substring(11, 19);
                      let timePos1 = time1 < '12:00:00' ? time1 : time2 < '12:00:00' ? time2 : '';
                      let timePos2 = time2 >= '12:00:00' ? time2 : time1 >= '12:00:00' ? time1 : '';
                      let isLate = timePos1 && timePos1 >= '08:46:00' && timePos1 < '10:00:00';
                      let isMissFirstHalf = timePos1 && timePos1 >= '10:00:00' && timePos1 < '12:00:00';
                      let isOutEarly = timePos2 && timePos2 < '16:30:00';
                      let isMissed = false;
                      let isLeaveFirstHalfMiss = false;
                      let leaveFirstHalf = false;
                      let leaveLastHalf = false;
                      let leaveFull = false;
                      let leaveTypeName = '';
                      let isRestDay = restDayList.find((v: any) => v.date == Moment(data2.date).format('YYYY-MM-DD'));
                      (() => {
                        dataMain.rows.filter((data3: any) => { return data3.user_id == data1.user?.id }).map((data3: any) => {
                          data3.leave_detail_days.filter((data4: any) => { return data4.day == day }).map((data4: any) => {
                            if(data4.status == 'approved') {
                              if(data4.total_leave_time == 'ครึ่งวันเช้า') { leaveFirstHalf = true; }
                              else if(data4.total_leave_time == 'ครึ่งวันบ่าย') { leaveLastHalf = true; }
                              else if(data4.total_leave_time == 'เต็มวัน') { leaveFull = true; }
                              if(data4.leave_type_name) {
                                leaveTypeName = data4.leave_type_name;
                                if(leaveFirstHalf) { leaveTypeName += ' (เช้า)'; }
                                else if(leaveLastHalf) { leaveTypeName += ' (บ่าย)'; }
                              }
                            }
                          });
                        });
                      })();
                      if(leaveFirstHalf) {
                        timePos1 = time1 < '13:00:00' ? time1 : time2 < '13:00:00' ? time2 : '';
                        isLeaveFirstHalfMiss = timePos1 >= '13:00:00';
                        if(isLeaveFirstHalfMiss) { isMissed = true; }
                      } else {
                        if(!timePos1) { isMissed = true; }
                      }
                      found = true;
                      html = <View key={index2} style={{ flexDirection: 'row' }}>
                        <View style={{ width: '37%', marginLeft: 10 }}></View>
                        <Text style={{ fontFamily: 'kanit-200', width: '10%', fontSize: 10, textAlign: 'left', marginLeft: 5 }}>
                          {getThaiDayName(data2.date) + " " + Moment(data2.date).format('DD')}
                        </Text>
                        <Text style={{ fontFamily: 'kanit-200', width: '8%', fontSize: 10, textAlign: 'left', marginLeft: 5 }}>
                          {/* {Moment(data2.first_check_in_time).locale("th").format('HH:mm:ss')} */}
                          { timePos1 ? timePos1 : '-' }
                        </Text>
                        <Text style={{ fontFamily: 'kanit-200', width: '13%', fontSize: 10, textAlign: 'left', marginLeft: 5 }}>
                          {
                            (() => {
                              let html2: any = '';

                              if(d.isSameOrBefore(today)) {
                                if(isRestDay) {
                                  html2 += ` วันหยุดนักขัตฤกษ์`;
                                } else {
                                  if(!leaveFull) {
                                    if(isLate) {
                                      html2 += ` สาย`;
                                    } else if(isMissFirstHalf) {
                                      html2 += ` ขาดครึ่งวัน`;
                                    } else if(isLeaveFirstHalfMiss || isMissed) {
                                      html2 += ` ขาดงาน`;
                                    }
                                  }
                                }
                              }
                              
                              html2 += ` ${ leaveTypeName }`;

                              // dataMain.rows.filter((data3: any) => { return data3.user_id == data1.user?.id }).map((data3: any) => {
                              //   data3.leave_detail_days.filter((data4: any) => { return data4.day == day }).map((data4: any) => {
                              //     // html2 += data4.leave_type_name ? data4.leave_type_name : '-';
                              //     html2 += data4.leave_type_name ? ' ' + data4.leave_type_name : '';
                              //   });
                              // });
                              html2 = html2.trim();
                              return html2;
                            })()
                          }
                        </Text>
                        <Text style={{ fontFamily: 'kanit-200', width: '8%', fontSize: 10, textAlign: 'left', marginLeft: 5 }}>
                          {/* {Moment(data2.last_check_in_time).locale("th").format('HH:mm:ss')} */}
                          { timePos2 ? timePos2 : '-' }
                        </Text>
                        <Text style={{ fontFamily: 'kanit-200', width: '13%', fontSize: 10, textAlign: 'left', marginLeft: 5 }}>
                          {
                            (() => {
                              let html2: any = '';

                              if(d.isSameOrBefore(today)) {
                                if(isOutEarly && !leaveLastHalf) {
                                  html2 += ` ออกก่อนเวลา`;
                                }
                              }

                              html2 = html2.trim();
                              return html2;
                            })()
                          }
                        </Text>
                      </View>
                    }

                    if(!found) {
                      let d = Moment().set('year', parseInt(Moment(queryParams.date).format('YYYY'))).set('month', parseInt(Moment(queryParams.date).format('MM'))-1).set('date', day);
                      let leaveTypeName = '';
                      let leaveFull = false;
                      (() => {
                        dataMain.rows.filter((data3: any) => { return data3.user_id == data1.user?.id }).map((data3: any) => {
                          data3.leave_detail_days.filter((data4: any) => { return data4.day == day }).map((data4: any) => {
                          //data3.leave_detail.filter((data4: any) => { return parseInt(Moment(data4.start_date).format('DD')) == day }).map((data4: any) => {
                            //if(data4.total_leave_time == 'ครึ่งวันเช้า') { leaveFirstHalf = true; }
                            if(data4.status == 'approved') {
                              if(data4.total_leave_time == 'เต็มวัน') { leaveFull = true; }
                              if(data4.leave_type_name) { leaveTypeName = data4.leave_type_name; }
                            }
                          });
                        });
                      })();
                      let isRestDay = restDayList.find((v: any) => v.date == d.format('YYYY-MM-DD'));
                      html = <View key={day} style={{ flexDirection: 'row' }}>
                      <View style={{ width: '37%', marginLeft: 10 }}></View>
                      <Text style={{ fontFamily: 'kanit-200', width: '10%', fontSize: 10, textAlign: 'left', marginLeft: 5 }}>
                        {getThaiDayName(d.format('YYYY-MM-DD')) + " " + d.format('DD')}
                      </Text>
                      <Text style={{ fontFamily: 'kanit-200', width: '8%', fontSize: 10, textAlign: 'left', marginLeft: 5 }}>
                        {'-'}
                      </Text>
                      <Text style={{ fontFamily: 'kanit-200', width: '13%', fontSize: 10, textAlign: 'left', marginLeft: 5 }}>
                        {
                          (() => {
                            let html2: any = '';
  
                            if(d.isSameOrBefore(today)) {
                              if(isRestDay) {
                                html2 += ` วันหยุดนักขัตฤกษ์`;
                              } else {
                                if(!leaveFull) {
                                  if([1,2,3,4,5].includes(d.get('day'))) {
                                    html2 += ` ขาดงาน`;
                                  }
                                }
                              }
                            }
                            
  
                            html2 += ` ${ leaveTypeName }`;
                            html2 = html2.trim();
                            return html2;
                          })()
                        }
                      </Text>
                      <Text style={{ fontFamily: 'kanit-200', width: '8%', fontSize: 10, textAlign: 'left', marginLeft: 5 }}>
                        {'-'}
                      </Text>
                      <Text style={{ fontFamily: 'kanit-200', width: '13%', fontSize: 10, textAlign: 'left', marginLeft: 5 }}>
                        {''}
                      </Text>
                    </View>
                    }
                  })
                  
                  //console.log('AAA', day, found);
                  return html;
                }) }

                

                <View style={{ marginTop: 10 }} />
              </>
            })}
          </View>
        </View>
      </Page>
    </Document>
  );

  return <>
    <div className="d-flex justify-content-between py-4">
      <h2 className="text-mof-primary m-0">รายงานสถิติเข้า - ออกงานและการลา</h2>
      <a href="/report/report-in-out-leave/report-in-out-leave">
        <Button variant="" className="btn-action me-2 btn-outline-secondary" onClick={() => { }}>
          <FontAwesomeIcon icon={faCaretLeft} className="me-2" />
          ย้อนกลับ
        </Button>
      </a>
    </div>

    {loaderComponent}

    <h5 className="text-mof-primary m-0">{ Moment(queryParams.date).format('DD/MM/YYYY') /*`${day}/${month}/${year}`*/ }</h5>
    <br />

    <div className="row">
      <div className="col-sm-12 col-md-3">
          <label className="form-label">วันที่</label>
          <input className="form-control" type="date" value={queryParams.date}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setQueryParams({
                      ...queryParams,
                      page: 1,
                      date: e.target.value
                    });

                    setQueryParamsExport({
                      ...queryParamsExport,
                      month: parseInt(Moment(e.target.value).format('MM')),
                      year: parseInt(Moment(e.target.value).format('YYYY')),
                    });
                  }} name="search"/>
      </div>
      {
        (() => {
          if(isAdmin) {
            return <>
              <div className="flex-row col-sm-12 col-md-3">
                <label className="form-label">สำนัก/ฝ่าย</label>
                <InputSelect value={queryParams.department_offices_name} options={department_offices_name_Options} placeholder="" name="is_active"
                  onChange={(e: any) => {
                    setQueryParams(prevState => {
                      return {
                        ...prevState,
                        department_offices_name: e.value.value || ""
                      }
                    })
                  }}
                  required={true}
                  isValidateError={false} />
              </div>
            </>
          }
        })()
      }
      {
        (() => {
          if(isAdmin) {
            return <>
              <div className="flex-row col-sm-12 col-md-3">
                <label className="form-label">กอง/กลุ่ม</label>
                <InputSelect value={queryParams.division_group_office_name} options={division_group_office_name_Options} placeholder="" name="is_active"
                  onChange={(e: any) => {
                    setQueryParams(prevState => {
                      return {
                        ...prevState,
                        division_group_office_name: e.value.value || ""
                      }
                    })
                  }}
                  required={true}
                  isValidateError={false} />
              </div>
            </>
          }
        })()
      }
      <div className="flex-row col-sm-12 col-md-3">
        <label className="form-label">แผนก</label>
        <InputSelect value={queryParams.structure_departments_name} options={departments_name_Options} placeholder="" name="is_active"
          onChange={(e: any) => {
            setQueryParams(prevState => {
              return {
                ...prevState,
                structure_departments_name: e.value.value || ""
              }
            })
          }}
          required={true}
          isValidateError={false} />
      </div>
      <div className="flex-row col-sm-12 col-md-3">
        <label className="form-label">ตำแหน่ง</label>
        <InputSelect value={queryParams.position_name} options={position_name_Options} placeholder="" name="is_active"
          onChange={(e: any) => {
            setQueryParams(prevState => {
              return {
                ...prevState,
                position_name: e.value.value || ""
              }
            })
          }}
          required={true}
          isValidateError={false} />
      </div>
      <div className="col-sm-12 col-md-3 d-flex align-items-end mt-4">
        <input className="form-control" type="text" value={queryParams.search as string}
          placeholder="ค้นหาจากชื่อ..." name="search"
          onChange={(e) => {
            setQueryParams(prevState => {
              return {
                ...prevState,
                search: e.target.value || ""
              }
            })
          }} />
      </div>
      <div className="col-sm-12 col-md-3 d-flex align-items-end mt-3 gap-3">
        <Button variant="primary" type="button" className="d-block px-4" onClick={fetchData}>
          <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
        </Button>

        {
          dataExportAll.count === 0 ?
            // <Button type="button" className="btn-mof-primary btn btn-primary" onClick={() => { setQueryParamsExport({ ...queryParamsExport, user_id: '' }) }}>สร้าง PDF</Button>
            <Button type="button" className="btn-mof-primary btn btn-primary" onClick={() => { alert('WIP'); }}>สร้าง PDF</Button>
            :
            <PDFDownloadLink document={queryParamsExport.user_id === '' ? <PDF data={dataExportAll} /> : <></>} fileName={`รายงานสถิติเข้า-ออกงานและการลา (${thaiMonths[parseInt(Moment(queryParams.date).format('MM')) - 1]} ${Moment(queryParams.date).format('YYYY')}).pdf`}>
              {({ blob, url, loading, error }) => (
                loading ?
                  <Button type="button" className="btn-mof-primary btn btn-primary" disabled>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span className="sr-only">Loading...</span>
                  </Button>
                  :
                  <Button type="button" className="btn-mof-primary btn btn-primary" onClick={() => setQueryParamsExport({ ...queryParamsExport, user_id: '-' })}>ดาวน์โหลด PDF</Button>
              )}
            </PDFDownloadLink>
        }
      </div>
    </div>

    <div className="overflow-auto mt-3">
      <Table striped hover>
        <thead>
          <tr className="align-middle text-center">
            <th rowSpan={2} className="border min-150">ชื่อ-สกุล</th>
            <th rowSpan={2} className="border min-150">สำนัก/ฝ่าย</th>
            <th rowSpan={2} className="border min-150">กอง/กลุ่ม</th>
            <th rowSpan={2} className="border min-150">แผนก</th>
            <th rowSpan={2} className="border min-150">ตำแหน่ง</th>
            <th colSpan={leave_type_Options.slice(1).length} className="border min-150">วันลาประจำเดือน{thaiMonths[parseInt(Moment(queryParams.date).format('MM')) - 1]} {Moment(queryParams.date).format('YYYY')}</th>
            <th colSpan={leave_type_Options.slice(1).length} className="border min-150">รวมวันลาทั้งหมด</th>
            <th rowSpan={2} className="border min-150">Export PDF</th>
          </tr>
          <tr className="align-middle text-center">
            {
              leave_type_Options.slice(1).map((data: any, index: any) => {
                return <td key={index} style={{ backgroundColor: '#6C757D', color: 'white', fontWeight: 'bold' }} className="border">{data.label}</td>
              })
            }
            {
              leave_type_Options.slice(1).map((data: any, index: any) => {
                return <td key={index} style={{ backgroundColor: '#6C757D', color: 'white', fontWeight: 'bold' }} className="border">{data.label}</td>
              })
            }
          </tr>
        </thead>
        <tbody>
          {
            data.rows.map((data: any, index: any) => {
              return <tr key={index} className="text-center">
                <td>{data.prefix_name_th + data.firstname_th + " " + data.lastname_th}</td>
                <td>{data.job?.department_offices_name ? data.job?.department_offices_name : '-'}</td>
                <td>{data.job?.division_group_office_name ? data.job?.division_group_office_name : '-'}</td>
                <td>{data.job?.structure_departments_name ? data.job?.structure_departments_name : '-'}</td>
                <td>{data.job?.position_name ? data.job?.position_name : '-'}</td>
                {
                  leave_type_Options.slice(1).map((leave_type: any, index: any) => {
                    for (let i = 0; i < data.leave?.length; i++) {
                      if (leave_type.value == data.leave[i]?.id) {
                        return <td key={index}>{data.leave[i]?.count}</td>
                      }
                    }
                    return <td>0</td>
                  })
                }
                {
                  leave_type_Options.slice(1).map((leave_type: any, index: any) => {
                    for (let i = 0; i < data.leave_all?.length; i++) {
                      if (leave_type.value == data.leave_all[i]?.id) {
                        return <td key={index}>{data.leave_all[i]?.count}</td>
                      }
                    }
                    return <td>0</td>
                  })
                }
                <td align='center'>
                  <Button type="button" variant="primary" className="d-block" onClick={() => {
                    setQueryParamsExport({
                      ...queryParamsExport,
                      user_id: data.user_id
                    })

                    startLoading()

                    setTimeout(() => {
                      if (refButtonExport.current[index]) {
                        refButtonExport.current[index]?.click();
                      }

                      stopLoading()
                      setQueryParamsExport({ ...queryParamsExport, user_id: '-' })
                    }, 5000);
                  }}>
                    <FontAwesomeIcon icon={faFileExport} />
                  </Button>
                  <PDFDownloadLink document={queryParamsExport.user_id !== '' && queryParamsExport.user_id !== '-' ? <PDF data={dataExport} /> : <></>} fileName={`รายงานสถิติเข้า-ออกงานและการลา (${data.prefix_name_th + data.firstname_th + " " + data.lastname_th}).pdf`}>
                    {({ blob, url, loading, error }) => (
                      <span ref={(el: any) => refButtonExport.current[index] = el}></span>
                    )}
                  </PDFDownloadLink>
                </td>
              </tr>
            })
          }
          {tableRowNoData()}
        </tbody>
      </Table>
    </div>

    <div className="">
      <PaginationForm
        current_page={queryParams.page}
        count={data.count}
        page_size={queryParams.page_size}
        onChangePageSize={onChangePageSize}
        onChangePage={onChangePage}
      />
    </div>

  </>
}

export default ReportInOutLeaveDetailPage;