import apiService from '../../api-service';

const REQUEST_BASE_API = process.env.REACT_APP_API_URI + '/report';

export class ReportInOutLeaveService {
  static async getReportInOutLeaveList(data: any) {
    const response = await apiService().get(`${REQUEST_BASE_API}/get_stat_leave`, {params: data});
    return response.data;
  }

  static async getReportInOutLeaveListV2(data: any) {
    const response = await apiService().get(`${REQUEST_BASE_API}/get_stat_leave_v2`, {params: data});
    return response.data;
  }

  static async getReportInOutLeaveDetail(data: any) {
    const response = await apiService().get(`${REQUEST_BASE_API}/leave_table`, {params: data});
    return response.data;
  }

  static async getReportInOutLeaveDetailMonth(data: any) {
    const response = await apiService().get(`${REQUEST_BASE_API}/leave_table_month`, {params: data});
    return response.data;
  }

  static async getReportInOutLeaveDetail_Export(data: any) {
    const response = await apiService().get(`${REQUEST_BASE_API}/get_stat_person`, {params: data});
    return response.data;
  }

  static async getReportNotScanInOutList(data: any) {
    const response = await apiService().get(`${REQUEST_BASE_API}/get_check_in_count`, {params: data});
    return response.data;
  }

  static async getReportPersonnelWorkEachShiftList(data: any) {
    const response = await apiService().get(`${REQUEST_BASE_API}/get_shift_list`, {params: data});
    return response.data;
  }

  static async getReportPersonnelWorkEachShiftDetail(data: any) {
    const response = await apiService().get(`${REQUEST_BASE_API}/get_shift_data`, {params: data});
    return response.data;
  }

  static async getReportChangeShiftList(data: any) {
    const response = await apiService().get(`${REQUEST_BASE_API}/get_shift_changes`, {params: data});
    return response.data;
  }

  static async getReportAnnualVacationList(data: any) {
    const response = await apiService().get(`${REQUEST_BASE_API}/shift_holiday_details`, {params: data});
    return response.data;
  }
}