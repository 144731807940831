import React, {ChangeEvent, FC, useEffect, useState} from 'react';
import useSpinnerLoader from '../../shared/components/spinner-loader';
import {Button, Form} from 'react-bootstrap';
import {notificationService} from '../../shared/services/notification-service';
import {useAuth} from '../../shared/authentications/auth-context';
import {useNavigate} from 'react-router-dom';
import {LeaveCondition, LeaveRequestTypeOptions, TotalLeaveTime} from "../../shared/utils/constants";
import InputSelect from "../../shared/components/atoms/input-select";
import {FormControlLabel, Radio, RadioGroup, TextField} from "@mui/material";
import DateInput from "../../shared/components/atoms/date-input";
import LeaveFileForm from "./components/file-form";
import {convertDateToString} from "../../shared/utils/functions-date-time";
import {fileToBase64} from "../../shared/utils/function-attachment";
import {LeaveRequestService} from "../../shared/services/leave-request";
import {AxiosError} from "axios";
import {SystemLogService} from "../../shared/services/system-log-service";
import {apiDropdownService} from "../../shared/services/dropdown-service";
import {apiEmployeeService} from "../../shared/services/employee-service";
import HeaderEmployeeSearch from '../organizational-structures/components/inputs/header-employee-search';
import { Dropdown } from '../../shared/models/common.model';


interface File {
  file: string | File | null;
  file_name: string;
  new_file?: boolean;
}

const EmployeeLeaveRequestPage: FC = () => {
  const [currentEmployee, setCurrentEmployee] = useState<any>(null);
  const {loaderComponent, isLoading, startLoading, stopLoading} = useSpinnerLoader();
  const [submitted, setSubmitted] = useState(false);
  const [totalLeaveTime, setTotalLeaveTime] = useState<any>("");
  const [leaveType, setLeaveType] = useState<any>("");
  const [leaveTypeName, setLeaveTypeName] = useState<any>("");
  const [leaveTypeOptions, setLeaveTypeOptions] = useState<any>([]);
  const [leaveDate, setLeaveDate] = useState<any>({
    start_date: null,
    end_date: null,
  });
  const [leaveReason, setLeaveReason] = useState<any>("");
  const [approverUserCode, setApproverUserCode] = useState<any>("");
  
  const [file, setFile] = useState<File>({
    file: null,
    file_name: '',
    new_file: false
  });
  const [leaveTypeDayLeft, setLeaveTypeDayLeft] = useState<any>("");
  const [leaveLimitByTypeData, setLeaveLimitByTypeData] = useState<any>([]);
  const [approvedLeaveList, setApprovedLeaveList] = useState<any>([]);

  const [isInTenureDateCondition, setIsInTenureDateCondition] = useState<any>(false);
  const [dayDiff, setDayDiff] = useState<any>(0);

  const {authJwt} = useAuth();

  const createSystemLog = async (data: any) => {
    try {
      await SystemLogService.create(data);
    } catch (error) {
      const err = error as any;
      notificationService.dangerNotification('ระบบขัดข้อง', err.message);
    }
  }

  useEffect(() => {
    createSystemLog({
      employee_id: authJwt?.employee_id,
      module: 'leave-request',
      action: 'view',
    });
  }, []);

  const isNegativeLeaveTypeDayLeft = () => {
    if (leaveTypeDayLeft === "") {
      return false;
    }
    const dayLeft = leaveTypeDayLeft.split(" ")[0];
    return parseInt(dayLeft) < 0 || parseInt(dayLeft) === 0;
  }

  const fetchLeaveCountData = async () => {
    startLoading();
    try {
      const result = await LeaveRequestService.getLeaveHistoryTypeTotal({
        leave_type: leaveTypeName,
        page_type: "request_page"
      });
      if (result?.leaveLimitByTypeData?.length > 0) {
        setLeaveLimitByTypeData(result?.leaveLimitByTypeData)
      }
      if (result?.leaveByTypeDayLeft?.days === null && result?.leaveByTypeDayLeft?.hours === null && result?.leaveByTypeDayLeft?.minutes === null) {
        setLeaveTypeDayLeft("0 วัน 0 ชั่วโมง 0 นาที");
      } else {
        setLeaveTypeDayLeft(
          `${result?.leaveByTypeDayLeft?.days} วัน ${result?.leaveByTypeDayLeft?.hours} ชั่วโมง ${result?.leaveByTypeDayLeft?.minutes} นาที`);
      }
    } catch (error) {
      const err = error as AxiosError;
      notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
    } finally {
      stopLoading();
    }
  };

  const fetchApprovedLeaveList = async () => {
    startLoading();
    try {
      const response = await LeaveRequestService.getApprovedLeaveList();
      if (response) {
        setApprovedLeaveList(response);
      }
    } catch (error) {
      const err = error as any;
      notificationService.dangerNotification('ระบบขัดข้อง', err.message);
    } finally {
      stopLoading();
    }
  }

  const getLeaveTimeTotal = () => {
    const {start_date, end_date} = leaveDate;

    if (start_date === null || end_date === null) {
      return 0;
    }

    if (totalLeaveTime === "ครึ่งวันเช้า") {
      return 3.5;
    }
    if (totalLeaveTime === "ครึ่งวันบ่าย") {
      return 3.5;
    }
    const startDateObj = new Date(start_date);
    const endDateObj = new Date(end_date);

    const diffTime = Math.abs(endDateObj.getTime() - startDateObj.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays * 7;
  };

  const getLeaveTimeTotalText = () => {
    const {start_date, end_date} = leaveDate;

    if (start_date === null || end_date === null) {
      return 0;
    }

    if (totalLeaveTime === "ครึ่งวันเช้า" || totalLeaveTime === "ครึ่งวันบ่าย") {
      return 0.5;
    }
    const startDateObj = new Date(start_date);
    const endDateObj = new Date(end_date);

    const diffTime = Math.abs(endDateObj.getTime() - startDateObj.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays;
  };

  const getLeaveTimeTotalTextInput = () => {
    const {start_date, end_date} = leaveDate;

    if (start_date === null || end_date === null) {
      return 0;
    }

    if (totalLeaveTime === "ครึ่งวันเช้า" || totalLeaveTime === "ครึ่งวันบ่าย") {
      return "ครึ่งวัน";
    }
    const startDateObj = new Date(start_date);
    const endDateObj = new Date(end_date);

    const diffTime = Math.abs(endDateObj.getTime() - startDateObj.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays;
  };

  const [form, setForm] = useState<any>({
    status: "waiting_for_approver",
    leave_request_details: [
      {
        leave_limit_by_type_id: "",
        total_leave_time: "",
        start_date: "",
        end_date: "",
        total_hours: getLeaveTimeTotal(),
        total_hours_to_days: getLeaveTimeTotalText(),
        reason: "",
        attachment: "",
        approver_user_code: ""
      }
    ]
  })
  const navigate = useNavigate();

  const inputValidation = () => {
    let isError = false;
    if (form.leave_request_details[0].leave_limit_by_type_id === "" || form.leave_request_details[0].leave_limit_by_type_id === undefined) {
      isError = true;
    }
    if (form.leave_request_details[0].total_leave_time === "" || form.leave_request_details[0].total_leave_time === undefined) {
      isError = true;
    }
    if (form.leave_request_details[0].start_date === "" || form.leave_request_details[0].start_date === undefined) {
      isError = true;
    }
    if (form.leave_request_details[0].end_date === "" || form.leave_request_details[0].end_date === undefined) {
      isError = true;
    }
    if (form.leave_request_details[0].total_hours === null || form.leave_request_details[0].total_hours === 0) {
      isError = true;
    }
    if (form.leave_request_details[0].total_hours_to_days === null || form.leave_request_details[0].total_hours_to_days === 0) {
      isError = true;
    }
    return isError;
  }


  const onLeaveRequestSubmit = async () => {
    setSubmitted(true);
    if (inputValidation()) {
      return;
    }
    try {
      startLoading();
      const response = await LeaveRequestService.sendLeaveRequest(form)
      if (response) {
        notificationService.successNotification('ส่งคำขอเรียบร้อยเเล้ว', '');
        navigate('/employee-leaves/leave-history');
      }
    } catch (error) {
      const err = error as any;
      if (err.response?.status === 400) {
        notificationService.dangerNotification('ไม่พบข้อมูล', err.response.data.message);
      } else {
        notificationService.dangerNotification('ระบบขัดข้อง', err.message);
      }
    } finally {
      stopLoading();
    }
  };


  const handleLeaveTimeTypeChange = (event: any) => {
    setLeaveType(event.value.value);
    setLeaveTypeName(event.value.label);
    setTotalLeaveTime("");
    setLeaveDate({
      start_date: null,
      end_date: null,
    })
  }

  const handleLeaveTimeTotalChange = (event: any) => {
    setTotalLeaveTime(event.target.value);
  }

  const handleDateInput = (date: Date | null, type: string) => {
    if (type === 'start_date') {
      if (totalLeaveTime === "ครึ่งวันเช้า") {
        setLeaveDate({
          start_date: date ? convertDateToString(date) + " " + "08:30:00" : null,
          end_date: date ? convertDateToString(date) + " " + "12:00:00" : null,
        })
        return;
      }
      if (totalLeaveTime === "ครึ่งวันบ่าย") {
        setLeaveDate({
          start_date: date ? convertDateToString(date) + " " + "13:00:00" : null,
          end_date: date ? convertDateToString(date) + " " + "16:30:00" : null,
        })
        return;
      }
      setLeaveDate((prev: Date) => {
        return {
          ...prev,
          start_date: date ? convertDateToString(date) + " " + "08:30:00" : null,
        }
      })
    }
    if (type === 'end_date') {
      if (totalLeaveTime === "ครึ่งวันเช้า") {
        setLeaveDate({
          start_date: date ? convertDateToString(date) + " " + "08:30:00" : null,
          end_date: date ? convertDateToString(date) + " " + "12:00:00" : null,
        })
        return;
      }
      if (totalLeaveTime === "ครึ่งวันบ่าย") {
        setLeaveDate({
          start_date: date ? convertDateToString(date) + " " + "13:00:00" : null,
          end_date: date ? convertDateToString(date) + " " + "16:30:00" : null,
        })
        return;
      }
      setLeaveDate((prev: Date) => {
        return {
          ...prev,
          end_date: date ? convertDateToString(date) + " " + "16:30:00" : null,
        }
      })
    }
  };


  const handleLeaveReasonChange = (event: any) => {
    setLeaveReason(event.target.value);
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (fileList && fileList.length > 0) {
      fileToBase64(fileList[0]).then(base64String => {
        setFile({
          file: base64String as string,
          file_name: fileList[0].name,
          new_file: true
        });
      });
    }
  };

  const handleConfirmDelete = async () => {
    setFile(
      {
        file: null,
        file_name: '',
        new_file: true
      }
    )
  };

  const FieldValidation = () => {
    let isError = false;
    if (leaveType === undefined || leaveType === "" && submitted) {
      isError = true;
    }
    return isError;
  }

  const getLeaveDescription = (leaveType: string) => {
    const getLeaveTypeName = leaveTypeOptions.find((data: any) => data.value === leaveType)?.label || "";
    return leaveLimitByTypeData.find((data: any) => data.leave_type_name === getLeaveTypeName)?.condition || "";
  };

  const isLeaveInAdvance = (leaveTypeName: string) => {
    const matchLeaveType = leaveLimitByTypeData.find((data: any) => data.leave_type_name === leaveTypeName);
    if (matchLeaveType) {
      return matchLeaveType?.is_leave_in_advance;
    }
    return false;
  }

  useEffect(() => {
    setForm({
      ...form,
      status: "waiting_for_approver",
      leave_request_details: [
        {
          leave_limit_by_type_id: leaveType,
          total_leave_time: totalLeaveTime,
          start_date: leaveDate.start_date,
          end_date: leaveDate.end_date,
          total_hours: getLeaveTimeTotal(),
          total_hours_to_days: getLeaveTimeTotalText(),
          reason: leaveReason,
          attachment: file.file || "",
          approver_user_code: approverUserCode
        }
      ]
    })

  }, [leaveDate, leaveReason, leaveType, totalLeaveTime, submitted, file.file, approverUserCode]);

  useEffect(() => {
    setLeaveDate({
      start_date: null,
      end_date: null,
    })
    setForm((prevState: any) => ({
      ...prevState,
      leave_request_details: [
        {
          ...prevState.leave_request_details[0],
          start_date: null,
          end_date: null,
        }
      ]
    }))

  }, [totalLeaveTime]);

  useEffect(() => {
    if (leaveType !== undefined || leaveType !== "") {
      fetchLeaveCountData();
    }
  }, [leaveType]);

  const leaveRequestDropdown = async () => {
    const leaveRequestTypeOptions = await apiDropdownService.getLeaveRequestDropdown({});
    if (leaveRequestTypeOptions) {
      setLeaveTypeOptions(leaveRequestTypeOptions);
    }
  }

  const getIsRetroPattern = () => {
    const getLeaveTypeName = leaveTypeOptions.find((data: any) => data.value === leaveType)?.label || "";
    return leaveLimitByTypeData.find((data: any) => data.leave_type_name === getLeaveTypeName)?.is_retro_pattern || "";
  }

  const getAllowLeaveTime = () => {
    const getLeaveTypeName = leaveTypeOptions.find((data: any) => data.value === leaveType)?.label || "";
    return leaveLimitByTypeData.find((data: any) => data.leave_type_name === getLeaveTypeName)?.minimum_leave || "";

  }

  function getLeaveDates(data: any[]): Array<[string, string]> {
    let dates: Array<[string, string]> = [];
    if (data?.length > 0) {
      data?.forEach(item => {
        item.leave_request_details.forEach((detail:any) => {
          dates.push([detail.start_date, detail.end_date]);
        });
      });
    }
    return dates;
  }

  function isLeaveOverlapping(newLeaveStart: string, newLeaveEnd: string): { overlap: boolean, details?: any } {
    const startDate = new Date(newLeaveStart);
    const endDate = new Date(newLeaveEnd);

    for (let [existingStart, existingEnd] of getLeaveDates(approvedLeaveList.rows)) {
      const existingStartDate = new Date(existingStart);
      const existingEndDate = new Date(existingEnd);

      if ((startDate <= existingEndDate && startDate >= existingStartDate) ||
        (endDate <= existingEndDate && endDate >= existingStartDate) ||
        (startDate <= existingStartDate && endDate >= existingEndDate)) {
        return { overlap: true, details: [existingStart, existingEnd] };
      }
    }
    return { overlap: false };
  }

  const overlap = isLeaveOverlapping(leaveDate.start_date, leaveDate.end_date);

  function convertToThaiDate(gregorianDateString: Date): string {
    // Parse the Gregorian date string
    const gregorianDate = new Date(gregorianDateString);

    // Add 543 to convert to Thai year
    const thaiYear = gregorianDate.getFullYear() + 543;

    // Thai month abbreviations
    const thaiMonths = [
      'ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.',
      'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'
    ];

    // Format the date in Thai date format
    const day = gregorianDate.getDate();
    const month = thaiMonths[gregorianDate.getMonth()];
    const thaiDateStr = `${day} ${month} ${thaiYear}`;

    return thaiDateStr;
  }

  const fetchEmployee = async () => {
    startLoading();
    try {
      const response = await apiEmployeeService.getEmployeeMe();
      if (response) {
        setCurrentEmployee(response);
      }
    } catch (error) {
      const err = error as any;
      notificationService.dangerNotification('ระบบขัดข้อง', err.message);
    } finally {
      stopLoading();
    }
  }

  const findInTenureDateCondition = (leaveTypeName: string): { isInCondition: boolean; totalTenureDate?: number } => {
    const today = new Date();
    const matchLeaveType = leaveLimitByTypeData.find((data: any) => data.leave_type_name === leaveTypeName);
    const employeePositionTenureDate = currentEmployee?.job_positions[0]?.tenure_position_date || null;
    if (!employeePositionTenureDate) {
      return {isInCondition: false, totalTenureDate: 0};
    }
    const startDate = employeePositionTenureDate ? new Date(employeePositionTenureDate) : null;
    let daysDiff = 0;
    if (startDate) {
      const findTotalTenureDateOnToday = Math.abs(today.getTime() - startDate.getTime()) || 0;
      daysDiff = Math.ceil(findTotalTenureDateOnToday / (1000 * 3600 * 24));
    }
    if (matchLeaveType && currentEmployee) {
      const currentEmployeeLevel = currentEmployee?.job_positions.find((data: any) => data.is_currently)?.position_level || ""
      const leaveDetail  = matchLeaveType.leave_limit_by_type_details
      const matchTenureDate = leaveDetail.find((data: any) => {
        return data.leave_limit_employee_level_details?.find((data: any) => data.employee_level === Number(currentEmployeeLevel))
      });
      switch (matchTenureDate?.work_period) {
        case "ไม่ครบ 1 ปี":
          if (daysDiff < 365) {
            return {isInCondition: true, totalTenureDate: daysDiff};
          }
          return {isInCondition: false, totalTenureDate: daysDiff};
        case "1 ปี แต่ไม่เกิน 3 ปี":
          if (daysDiff >= 365 && daysDiff < 1095) {
            return {isInCondition: true, totalTenureDate: daysDiff};
          }
          return {isInCondition: false, totalTenureDate: daysDiff};
        case "3 ปี แต่ไม่เกิน 10 ปี":
          if (daysDiff >= 1095 && daysDiff < 3650) {
            return {isInCondition: true, totalTenureDate: daysDiff};
          }
          return {isInCondition: false, totalTenureDate: daysDiff};
        case "10 ปี เป็นต้นไป":
          if (daysDiff >= 3650) {
            return {isInCondition: true, totalTenureDate: daysDiff};
          }
          return {isInCondition: false, totalTenureDate: daysDiff};
      }
    }
    return {isInCondition: false, totalTenureDate: daysDiff};
  }

  useEffect(() => {
    leaveRequestDropdown();
  }, []);

  useEffect(() => {
    fetchApprovedLeaveList();
  }, []);

  useEffect(() => {
    fetchEmployee();
    const kv = findInTenureDateCondition(leaveTypeName)
    setIsInTenureDateCondition(kv.isInCondition)
    setDayDiff(kv.totalTenureDate)
  }, [leaveTypeName]);

  return <>
    <div className="d-flex justify-content-between py-4">
      <h2 className="text-mof-primary m-0">
        ลาออนไลน์
      </h2>
    </div>

    <Form noValidate validated={submitted} className="">
      <div className="row mt-3">
        <div className="col-sm-12 col-md-4">
          <label className="form-label required">ประเภทการลา</label>
          <InputSelect options={leaveTypeOptions} placeholder="กรุณาเลือกประเภทการลา" name="is_active"
                       onChange={handleLeaveTimeTypeChange} required={true}
                       isValidateError={FieldValidation()} isLeaveRequestPage={true} value={leaveType}/>
          {leaveType === undefined || leaveType === "" && submitted ?
            <div style={{color: "#ED5245", fontSize: "0.875em", marginTop: "0.25rem"}}>
              กรุณาเลือกประเภทการลา
            </div> : null}
        </div>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          className="col-sm-12 col-md-4 flex-row align-content-end column-gap-3 mt-sm-2 justify-content-around mt-2"
          value={totalLeaveTime}
          onChange={handleLeaveTimeTotalChange}
        >
          {TotalLeaveTime.map((data, index) => {
            return <FormControlLabel key={index} value={data.value}
                                     control={<Radio color={"success"}/>} label={data.label}
                                     disableTypography={true} disabled={isNegativeLeaveTypeDayLeft() || getAllowLeaveTime() !== "ครึ่งวัน" && data.label === "ครึ่งวันเช้า" || getAllowLeaveTime() !== "ครึ่งวัน" && data.label === "ครึ่งวันบ่าย"}/>
          })}
          {totalLeaveTime === undefined || totalLeaveTime === "" && submitted ?
            <div style={{color: "#ED5245", fontSize: "0.875em", marginTop: "0.25rem"}}>
              กรุณาเลือกเวลาการลา
            </div> : null}
        </RadioGroup>
        <div className={`col-sm-12 col-md-4 d-flex flex-column justify-content-end mt-2`}>
          <input type="text" className="form-control" name="code" disabled
                 placeholder="" value={leaveTypeDayLeft} required={true}
                 style={{color: isNegativeLeaveTypeDayLeft() ? "red" : ""}}/>
        </div>
        <label className="mt-2" style={{fontSize: "12px", color: "#707070"}}>{getLeaveDescription(leaveType)}</label>
      </div>

      <div className="row mt-3">
        <div className="col-sm-12 col-md-4">
          <div className={``}>
            <label className="form-label">วันเริ่ม</label>
            <DateInput date={leaveDate.start_date && new Date(leaveDate.start_date)}
                       onChange={(e) => {
                         handleDateInput(e, 'start_date')
                       }}
                       required={true}
                       minDate={getIsRetroPattern() ? (() => {
                         const date = new Date();
                         date.setDate(date.getDate() - 7);
                         return date;
                       })() : new Date()}
                       maxDate={!isLeaveInAdvance(leaveTypeName) ? new Date() : leaveDate.end_date ? new Date(leaveDate.end_date) : undefined}
                       disable={isNegativeLeaveTypeDayLeft()}/>
            <input type="date" className="form-control" name="birthday"
                   placeholder="" disabled={false} hidden={true}
                   value={(leaveDate.start_date && new Date(leaveDate.start_date).toISOString().slice(0, 10)) || ''}
                   required={true}
                   onChange={() => {
                   }}/>
            <div className="invalid-feedback">
              กรุณาเลือกวันเริ่มลา
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-4 mt-sm-3 mt-md-0">
          <div className={``}>
            <label className="form-label">จนถึง</label>
            <DateInput date={leaveDate.end_date && new Date(leaveDate.end_date)}
                       onChange={(e) => {
                         handleDateInput(e, 'end_date')
                       }}
                       required={true}
                       minDate={leaveDate.start_date ? new Date(leaveDate.start_date) : new Date()}
                       maxDate={!isLeaveInAdvance(leaveTypeName) ? new Date() : undefined}
                       disable={isNegativeLeaveTypeDayLeft()}/>
            <input type="date" className="form-control" name="birthday"
                   placeholder="" disabled={false} hidden={true}
                   value={(leaveDate.end_date && new Date(leaveDate.end_date).toISOString().slice(0, 10)) || ''}
                   required={true}
                   onChange={() => {
                   }}/>
            <div className="invalid-feedback">
              กรุณาเลือกวันสิ้นสุดการลา
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-4 mt-sm-3 mt-md-0">
          <div className={``}>
            <label className="form-label">จำนวนวัน</label>
            <input type="text" className="form-control" name="code"
                   placeholder="" value={getLeaveTimeTotalTextInput()}
                   required={true}
                   disabled={isNegativeLeaveTypeDayLeft()}
                   onChange={() => {
                   }}/>
            <div className="invalid-feedback">

            </div>
          </div>
        </div>
        {overlap.overlap && <label className="mt-2" style={{fontSize: "16px", color: "red"}}>{`ท่านได้ลาในช่วงระยะเวลานี้ไปเเล้ว ${convertToThaiDate(new Date(overlap.details[0]))} - ${convertToThaiDate(new Date(overlap.details[1]))}`}</label>}
        <div className="col-sm-12 col-md-12 mt-sm-3 mt-md-0 mt-xl-3">
          <div className={``}>
            <label className="form-label">เหตุผลการลา</label>
            <TextField
              id="outlined-multiline-static"
              className="form-control"
              multiline
              rows={4}
              onChange={handleLeaveReasonChange}
              disabled={isNegativeLeaveTypeDayLeft()}
            />
            <div className="invalid-feedback">
              กรุณากรอกรหัสพนักงาน
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 mt-3">
          <LeaveFileForm handleFileChange={handleFileChange} handleConfirmDelete={handleConfirmDelete}
                         file={file} disabled={isNegativeLeaveTypeDayLeft()}/>
        </div>
      </div>
      <div className="row mt-4 mb-5">
        <div className="col-sm-12 col-md-4 mt-sm-3 mt-md-0">
          <div className={``}>
            <label className="form-label">ผู้อนุมัติ</label>
            <input type="text" className="form-control" name="code"
                   placeholder="เลือกอัตโนมัติ กรอกรหัสพนักงานเพื่อกำหนดเอง..." value={approverUserCode}
                   required={false}
                   disabled={isNegativeLeaveTypeDayLeft()}
                   onChange={(e) => { setApproverUserCode(e.target.value); }}/>
            <div className="invalid-feedback">

            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4 mb-5">
        <div className="col-sm-8 col-md-8 col-lg-10">
        </div>
        <div className="col-sm-4 col-md-4 col-lg-2">
          <Button variant="primary" type="button"
                  onClick={onLeaveRequestSubmit}
                  className="d-block btn-action"
                  style={{background: "background: #007BFF"}}
                  disabled={isNegativeLeaveTypeDayLeft() || overlap.overlap}
          >
            ส่งใบลา
          </Button>
        </div>
      </div>
    </Form>

  </>
}

export default EmployeeLeaveRequestPage;