import {ChangeEvent, FC, useEffect, useState} from 'react';
import useSpinnerLoader from '../../../../shared/components/spinner-loader';
import {Button, Table} from 'react-bootstrap';
import {notificationService} from '../../../../shared/services/notification-service';
import {initialResponseData} from "../../../../shared/utils/constants";
import {ReportInOutLeaveService} from "../../../../shared/services/Report/ReportInOutLeave/report-in-out-leave";
import TableHeaderOrdering, {Header} from "../../../../shared/components/table-header-ordering";
import PaginationForm from "../../../../shared/components/molecules/commons/pagination-form";
import TableRowNoData from "../../../../shared/components/molecules/commons/table-row-no-data";
import {AxiosError} from "axios/index";
import {useNavigate} from 'react-router-dom';
import Moment from "moment";
import InputSelect from "../../../../shared/components/atoms/input-select";
import DateRangeInput, { DateRangeInputChange } from '../../../../shared/components/atoms/date-range-input';
import {convertDateToString} from "../../../../shared/utils/functions-date-time";
import {
  fetchDepartmentsOfficeNameOptions,
  fetchDivisionGroupOfficeNameOptions,
  fetchDepartmentsNameOptions,
  fetchPositionNameOptions,

  department_offices_name_Options,
  division_group_office_name_Options, 
  departments_name_Options, 
  position_name_Options, 
} from '../../OptionsDropDown';
import { useAuth } from '../../../../shared/authentications/auth-context';
import { apiEmployeeService } from "../../../../shared/services/employee-service";
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const headers: Header[] = [
  {
    label: 'วันที่',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'เข้างาน(คน)',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'ออกงาน(คน)',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'มาสาย',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'ลา',
    field: '',
    order: null,
    isOrder: false,
  }
];

const ReportInOutLeaveListPage: FC = () => {
  const { authJwt } = useAuth();
  const isAdmin = authJwt?.role === 'ADMIN';
  const [isUserLoaded, setIsUserLoaded] = useState(false);
 
  const getEmployeeMe = async () => {
    const response = await apiEmployeeService.getEmployeeMe();

    if(response.length !== 0 && !isAdmin) {
      let arr: Array<any> = response.job_positions.filter((v: any) => v.is_currently);
      let division_group_office_name = arr.at(0)?.division_group_office_name || '';
      setIsUserLoaded(true);
      setQueryParams({
        ...queryParams,
        division_group_office_name: division_group_office_name
      });
    } else {
      setIsUserLoaded(true);
      setQueryParams(queryParams);
    }

  }

  useEffect(() => {
    getEmployeeMe()
  }, []);

  //

  // const [data, setData] = useState(initialResponseData);
  const [data, setData] = useState<any>({});
  const [queryParams, setQueryParams] = useState({
    department_offices_name: '',
    division_group_office_name: '',
    structure_departments_name: '',
    position_name: '',
    date_start: '',
    date_end: '',
    page: 1,
    page_size: 10,
    displayType: 'month',
    year: Moment().format('YYYY'),
    month: String(parseInt(Moment().format('MM'))),
    budgetYear: (() => {
      let y = parseInt(Moment().format('YYYY'))+543;
      if(parseInt(Moment().format('MM')) >= 10) { ++y; }
      return String(y);
    })(),
  });

  const {loaderComponent, startLoading, stopLoading} = useSpinnerLoader();

  const navigate = useNavigate();

  const fetchData = async () => {
    if(!isUserLoaded) { return; }
    startLoading();
    try {
      const result = await ReportInOutLeaveService.getReportInOutLeaveListV2({
        // division_group_office_name: queryParams.division_group_office_name,
        // structure_departments_name: queryParams.structure_departments_name,
        // position_name: queryParams.position_name,
        // date_start: queryParams.date_start,
        // date_end: queryParams.date_end,
        year: queryParams.displayType == 'month' ? queryParams.year : parseInt(queryParams.budgetYear)-543,
        month: queryParams.month,
        display_type: queryParams.displayType
        // page: queryParams.page,
        // page_size: queryParams.page_size
      });
      setData(result);
    } catch (error) {
      const err = error as AxiosError;
      notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
    } finally {
      stopLoading();
    }
  };

  const tableRowNoData = () => {
    if (data.rows?.length === 0) {
      return <TableRowNoData colspan={headers.length}/>;
    }
    return null;
  };

  const onChangePageSize = (page_size: number) => {
    setQueryParams({
      ...queryParams,
      page_size: page_size
    });
  };

  const onChangePage = (page: number) => {
    setQueryParams({
      ...queryParams,
      page: page
    });
  };

  const onChangeDisplayType = (e: ChangeEvent<HTMLSelectElement>) => {
    setQueryParams({
          ...queryParams,
          displayType: e.target.value
      });
  };

  const onChangeMonth = (e: ChangeEvent<HTMLSelectElement>) => {
    setQueryParams({
          ...queryParams,
          month: e.target.value
      });
  };

  const onChangeYear = (e: ChangeEvent<HTMLInputElement>) => {
      setQueryParams({
          ...queryParams,
          year: e.target.value
      });
  };

  const onChangeBudgetYear = (e: ChangeEvent<HTMLInputElement>) => {
    setQueryParams({
        ...queryParams,
        budgetYear: e.target.value
    });
};

  useEffect(() => {
    fetchData();
  },[
    queryParams.department_offices_name,
    queryParams.division_group_office_name,
    queryParams.structure_departments_name,
    queryParams.position_name,
    queryParams.date_start, 
    queryParams.date_end,
    queryParams.page_size, 
    queryParams.page,
    isUserLoaded
  ]);

  useEffect(() => {
    fetchDepartmentsOfficeNameOptions();
    fetchDivisionGroupOfficeNameOptions();
    fetchDepartmentsNameOptions();
    fetchPositionNameOptions();
  }, []);

  const handleDateRangeChange = (value: DateRangeInputChange) => {
    setQueryParams({
      ...queryParams,
      date_start: value.startDate ? convertDateToString(value.startDate) : '',
      date_end: value.endDate ? convertDateToString(value.endDate) : ''
    });
  };

  // ================================

  return <div>
    <div className="d-flex justify-content-between py-4">
      <h2 className="text-mof-primary m-0">รายงานสถิติเข้า - ออกงานและการลา</h2>
    </div>

    {loaderComponent}

    <div className="row">
      <div className="col-sm-12 col-md-2">
        <label className="form-label">การแสดงผล</label>
        <select className="form-control" onChange={onChangeDisplayType} name="search" value={queryParams.displayType}>
            <option value="month">รายเดือน</option>
            <option value="budget-year">ปีงบประมาณ</option>
        </select>
        {/* <input className="form-control" type="text" value={queryParams.month}
                onChange={onChangeMonth} name="search"/> */}
      </div>
      {
        (() => {
          if(queryParams.displayType == 'month') {
            return <>
              <div className="col-sm-12 col-md-2">
                <label className="form-label">เดือน</label>
                <select className="form-control" onChange={onChangeMonth} name="search" value={queryParams.month}>
                    <option value="">-- เลือก --</option>
                    <option value="1">มกราคม</option>
                    <option value="2">กุมภาพันธ์</option>
                    <option value="3">มีนาคม</option>
                    <option value="4">เมษายน</option>
                    <option value="5">พฤษภาคม</option>
                    <option value="6">มิถุนายน</option>
                    <option value="7">กรกฎาคม</option>
                    <option value="8">สิงหาคม</option>
                    <option value="9">กันยายน</option>
                    <option value="10">ตุลาคม</option>
                    <option value="11">พฤศจิกายน</option>
                    <option value="12">ธันวาคม</option>
                </select>
              </div>
              <div className="col-sm-12 col-md-2">
                  <label className="form-label">ปี</label>
                  <input className="form-control" type="text" value={queryParams.year}
                          onChange={onChangeYear} name="search"/>
              </div>
            </>
          }
        })()
      }
      
      {
        (() => {
          if(queryParams.displayType == 'budget-year') {
            return <>
              <div className="col-sm-12 col-md-2">
                <label className="form-label">ปีงบประมาณ</label>
                <input className="form-control" type="text" value={queryParams.budgetYear}
                        onChange={onChangeBudgetYear} name="search"/>
              </div>
            </>;
          }
        })()
      }

      <div className="col-sm-12 col-md-2">
        <div style={{ height: '31px' }}></div>
        <Button variant="primary" type="button" className="d-inline-block px-4" onClick={fetchData}>
            <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
        </Button>
      </div>

    </div>

    <br/><br/>

    <div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>ประเภท</th>
            <th>มา</th>
            <th>สาย</th>
            <th>ขาด</th>
          </tr>
        </thead>
        <tbody>
          {
            data.rows?.map((v: any) => {
              return <>
                <tr>
                  <td>{ v.employee_type }</td>
                  <td>{ v.on_time_count }</td>
                  <td>{ v.late_count }</td>
                  <td>{ '-' }</td>
                </tr>
              </>;
            })
          }
        </tbody>
      </Table>
    </div>

    <div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>ประเภท</th>
            <th>ลา</th>
          </tr>
        </thead>
        <tbody>
          {
            data.rows2?.map((v: any) => {
              return <>
                <tr>
                  <td>{ v.employee_type }</td>
                  <td>{ v.count }</td>
                </tr>
              </>;
            })
          }
        </tbody>
      </Table>
    </div>

  </div>;

  // return <div>
  //   <div className="d-flex justify-content-between py-4">
  //     <h2 className="text-mof-primary m-0">รายงานสถิติเข้า - ออกงานและการลา</h2>
  //   </div>

  //   {loaderComponent}

    

  //   <div className="row">
  //     {
  //       (() => {
  //         if(isAdmin) {
  //           return <>
  //             <div className="flex-row col-sm-12 col-md-3">
  //               <label className="form-label">สำนัก/ฝ่าย</label>
  //               <InputSelect value={queryParams.department_offices_name} options={department_offices_name_Options} placeholder="" name="is_active"
  //                 onChange={(e:any) => {
  //                   setQueryParams(prevState => {
  //                     return {
  //                       ...prevState,
  //                       department_offices_name: e.value.value || ""
  //                     }
  //                   })
  //                 }} 
  //                 required={true}
  //                 isValidateError={false}/>
  //             </div>
  //           </>
  //         }
  //       })()
  //     }
  //     {
  //       (() => {
  //         if(isAdmin) {
  //           return <>
  //           <div className="flex-row col-sm-12 col-md-3">
  //             <label className="form-label">กอง/กลุ่ม</label>
  //             <InputSelect value={queryParams.division_group_office_name} options={division_group_office_name_Options} placeholder="" name="is_active"
  //               onChange={(e:any) => {
  //                 setQueryParams(prevState => {
  //                   return {
  //                     ...prevState,
  //                     division_group_office_name: e.value.value || ""
  //                   }
  //                 })
  //               }} 
  //               required={true}
  //               isValidateError={false}/>
  //           </div>
  //           </>
  //         }
  //       })()
  //     }
  //     <div className="flex-row col-sm-12 col-md-3">
  //       <label className="form-label">แผนก</label>
  //       <InputSelect value={queryParams.structure_departments_name} options={departments_name_Options} placeholder="" name="is_active"
  //         onChange={(e:any) => {
  //           setQueryParams(prevState => {
  //             return {
  //               ...prevState,
  //               structure_departments_name: e.value.value || ""
  //             }
  //           })
  //         }} 
  //         required={true}
  //         isValidateError={false}/>
  //     </div>
  //     <div className="flex-row col-sm-12 col-md-3">
  //       <label className="form-label">ตำแหน่ง</label>
  //       <InputSelect value={queryParams.position_name} options={position_name_Options} placeholder="" name="is_active"
  //         onChange={(e:any) => {
  //           setQueryParams(prevState => {
  //             return {
  //               ...prevState,
  //               position_name: e.value.value || ""
  //             }
  //           })
  //         }} 
  //         required={true}
  //         isValidateError={false}/>
  //     </div>
  //     <div className="flex-row col-sm-12 col-md-3">
  //         <label className="form-label">ช่วงวันเริ่มต้น-สิ้นสุด</label>
  //         <DateRangeInput
  //           startDate={queryParams.date_start ? new Date(queryParams.date_start) : null}
  //           endDate={queryParams.date_end ? new Date(queryParams.date_end) : null}
  //           onChange={handleDateRangeChange}
  //           disable={false}
  //         />
  //     </div>
  //   </div>

  //   <div className="overflow-auto mt-3">
  //     <Table striped bordered hover>
  //       <thead>
  //         <TableHeaderOrdering headers={headers} onChangeOrder={() => {
  //         }} isShow={true}/>
  //       </thead>
  //       <tbody>
  //         {
  //           data.rows?.map((data: any,index) => {
  //             return <tr key={index} style={{cursor: "pointer"}} onClick={() => {
  //               window.location.href = (`report-in-out-leave/${Moment(data.date).utcOffset('+07:00').add(543,'year').format('DD/MM/YYYY').substring(0, 2)}/${Moment(data.date).utcOffset('+07:00').add(543,'year').format('DD/MM/YYYY').substring(3, 5)}/${Moment(data.date).utcOffset('+07:00').add(543,'year').format('DD/MM/YYYY').substring(6, 10)}/details`)
  //             }}>
  //               <td>{data.date ? Moment(data.date).utcOffset('+07:00').add(543,'year').format('DD/MM/YYYY') : '-'}</td>
  //               <td>{data.on_time_count ? data.on_time_count : '-'}</td>
  //               <td>{data.check_out_count ? data.check_out_count : '-'}</td>
  //               <td>{data.late_count ? data.late_count : '-'}</td>
  //               <td>{data.leave_count ? data.leave_count : '-'}</td>
  //             </tr>
  //           })
  //         }
  //         {tableRowNoData()}
  //       </tbody>
  //     </Table>
  //   </div>

  //   <div className="">
  //     <PaginationForm 
  //       current_page={queryParams.page} 
  //       count={data.count} 
  //       page_size={queryParams.page_size}
  //       onChangePageSize={onChangePageSize} 
  //       onChangePage={onChangePage}
  //     />
  //   </div>

  // </div>;
}

export default ReportInOutLeaveListPage;